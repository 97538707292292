<template>
  <div class="table">
    <el-button type="primary" size="mini" @click="adds" v-if="mold"
      >批量添加</el-button>
    <template v-else>
      <el-button type="primary" size="mini" @click="add">添加</el-button>
      <el-button type="primary" size="mini" @click="dele">批量删除</el-button>
    </template>
    <el-select
      v-model="value"
      placeholder="请选择"
      style="float:right"
      @change="select">
      <el-option
        v-for="(item, index) in typelist"
        :key="index"
        :label="item.type"
        :value="item.type">
        <span style="float: left">{{ item.type }}</span>
        <!-- <el-tag style="float: right">{{item.count}}</el-tag> -->
        <span style="float: right; color: #8492a6; font-size: 13px">
          {{item.count}}</span>
      </el-option>
    </el-select>
    <v-table
      :load="loading"
      :data="tableData"
      ref="list"
      @del="listconfig(type)"
      @addDevice="addDevice"
      :mold="mold"/>
  </div>
</template>

<script>
import { getId } from "@/utils/utils";
import VTable from "./table";
import DevicePower from "./index";
export default {
  name: "Device",
  data() {
    return {
      tableData: [],
      typelist: [],
      innerVisible: false,
      value: "",
      loading: false
    };
  },
  props: {
    type: {
      type: String,
    },
    mold: {
      type: Number,
    },
  },
  components: {
    VTable,
    DevicePower,
  },
  mounted() {
    this.listconfig(this.type);
  },
  methods: {
    closeload() {
      this.loading = false
    },
    openload() {
      this.loading = true
    },
    listnoset() {

    },
    listconfig(position) {
      this.openload()
      this.$axios({
        url: "/common4/companyHotelAligenieConfig/listHotelAligenieConfigType",
        method: "get",
        params: {
          position,
        },
      }).then((res) => {
        this.closeload()
        let data = res.data.result;
        console.log(data);
        this.typelist = data;
        this.value = this.value === "" ? data[0].type : this.value;
        if (this.mold) {
          this.nosetlist(this.type, this.value)
          this.findNoSer(this.type, this.value)
          return 
        };
        this.list(this.type, this.value);
      }).catch(err => {
        this.isError(err, this);
        this.closeload()
      });
    },
    // 开启添加面板
    add() {
      this.$emit("addevice");
    },
    adds() {
      let arr = [];
      let list = this.$refs.list.allcheked();
      if (!list.length)
        return this.$message({
          type: "error",
          message: "至少选择一项",
        });
      for (const i of list) {
        arr.push({
          hadHouseId: i.hsId,
          hadDeviceId: i.id,
        });
      }
      this.openload()
      this.$axios({
        url: "/common4/hotelAligenieDevice",
        method: "post",
        data: {
          position: this.type,
          deviceType: this.value,
          deviceList: arr,
        },
      })
        .then((res) => {
          this.$message({
            showClose: true,
            duration: 6000,
            message: "添加成功！",
            type: "success",
          });
          this.closeload()
          this.nosetlist(this.type)
          this.listconfig(this.type);
          this.relist()
        })
        .catch((err) => {
          this.closeload()
          this.isError(err, this);
        });
    },
    relist() {
      this.$emit('relist')
    },
    addDevice(row) {
      console.log(row);
      let arr = [];
      this.openload()
      arr.push({
        hadHouseId: row.hsId,
        hadDeviceId: row.id,
      });
      this.$axios({
        url: "/common4/hotelAligenieDevice",
        method: "post",
        data: {
          position: this.type,
          deviceType: this.value,
          deviceList: arr,
        },
      })
        .then((res) => {
          this.closeload()
          this.$message({
            showClose: true,
            duration: 6000,
            message: "添加成功！",
            type: "success",
          });
          // 重置数量
          this.nosetlist(this.type, this.value)
          this.findNoSer(this.type, this.value);
          this.relist()
        })
        .catch((err) => {
          this.closeload()
          this.isError(err, this);
        });
    },
    dele() {
      let arr = [...this.$refs.list.allcheked()];
      if (!arr.length)
        return this.$message({
          type: "error",
          message: "至少选择一项",
        });
        this.openload()
      let checked = getId(arr, "hadId"); //Array
      this.$axios({
        url: "/common4/hotelAligenieDevice/deleteConfiguredDevice",
        method: "post",
        data: checked,
      }).then((res) => {
        this.listconfig(this.type);
        this.nosetlist(this.tyep)
        this.closeload()
        this.$message({
            showClose: true,
            duration: 6000,
            message: "删除成功！",
            type: "success",
          })
      }).catch(err => {
        this.closeload()
        this.isError(err, this);
      });
      console.log(checked);
    },
    findNoSer(position, deviceType) {
      this.openload()
      this.$axios({
        url: "/common4/hotelAligenieDevice/notConfiguredDevice",
        method: "get",
        params: {
          position,
          deviceType,
        },
      }).then((res) => {
        this.closeload()
        let data = res.data.result;
        this.tableData = data;
      }).catch(err => {
        this.isError(err,this)
        this.closeload()
      });
    },
    nosetlist(position, deviceType) {
      this.$axios({
        url: "/common4/companyHotelAligenieConfig/hotelAligenieConfigTypeCount",
        method: "get",
        params: {
          position,
          deviceType,
        },
      }).then((res) => {
        let data = res.data.result;
        console.log(data)
        this.typelist = data;
      });
    },
    list(position, deviceType) {
      this.$axios({
        url: "/common4/hotelAligenieDevice/configuredDevice",
        method: "get",
        params: {
          position,
          deviceType,
        },
      }).then((res) => {
        let data = res.data.result;
        this.tableData = data;
      });
    },
    select(value) {
      console.log(value);
      if (this.mold) {
        this.nosetlist(this.type, this.value)
        this.findNoSer(this.type, this.value)
        return 
      };
      this.list(this.type, value);
    },
  },
};
</script>
