<template>
<div class="Part equipment_list">
    <div class="input">
        <el-row ref="wngRow">
            <!--功能按钮-->
            <el-button type="primary" v-if="autheMixin('添加智能设备')" size="mini" @click="addNewEq">添加智能设备</el-button>
            <el-dialog 
                v-dialogDrag 
                title="添加设备" 
                @open="addNewClose" 
                v-loading="loading1" 
                :visible.sync="dialogTianJiaXinSheBei" 
                :close-on-click-modal="false" 
                width="400px">
                <el-form :model="addBrandsForm.params" :rules="addBrandsForm.rules" ref="addBrandsFormRules">
                    <el-form-item label="选 择 类 型:" label-width="auto">
                        <el-cascader
                            @change="brandChange" 
                            v-model="brandId" 
                            size="mini" 
                            :options="brandsList" 
                            filterable 
                            :props="{ expandTrigger: 'hover' }" 
                            clearable 
                            style="width:250px">
                        </el-cascader>
                    </el-form-item>
                     <el-form-item v-if="brandId == 35" label="设 备:" label-width="auto">
                        <el-cascader
                            v-model="typeID"
                            size="mini" 
                            :options="leixingList" 
                            filterable 
                            :props="{ checkStrictly: false }" 
                            clearable
                            style="width:250px">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item v-if="[30, 31, 32, 33, 34, 23].indexOf(brandId) > -1" label="设 备 类 型:" label-width="auto" prop="secondType">
                        <el-select v-model="addBrandsForm.params.secondType" size="mini" style="width:250px">
                            <el-option v-for="item in faceDevice.typeList" :key="item.dstId" :label="item.dstName" :value="item.dstId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="brandId == 35" label="输 入 端 口 号:" label-width="auto">
                        <el-input size="mini" v-model="addBrandsForm.params.portNumber" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="[25, 22, 30, 31, 32, 33, 34, 23].indexOf(brandId) > -1" label="设备序列号:" label-width="auto" prop="deviceKey">
                        <el-input size="mini" v-model="toUpper" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="[25, 22, 30, 31, 32, 33, 34, 23,35].indexOf(brandId) > -1" label="设 备 名 称:" label-width="auto" prop="deviceName">
                        <el-input size="mini" v-model="addBrandsForm.params.deviceName" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="[31, 32, 33, 34,35].indexOf(brandId) > -1" label="设 备 ip:" label-width="auto" prop="ip">
                        <el-input size="mini" v-model="addBrandsForm.params.ip" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="brandId == 25" label="获 取 设 备:" label-width="auto">
                        <el-input size="mini" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="brandId == 25" label="门 禁 设 备:" label-width="auto">
                        <el-input size="mini" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="brandId == 29" label="设 备 账 号:" label-width="auto" prop="deviceAccount">
                        <el-input size="mini" v-model="addBrandsForm.params.deviceAccount" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <el-form-item v-if="brandId == 32 || brandId == 33 || brandId == 34" label="设备用户名:" label-width="auto" prop="deviceUserName">
                        <el-input size="mini" v-model="addBrandsForm.params.deviceUserName" clearable style="width:250px"></el-input>
                    </el-form-item>
                    <!-- || brandId == 23 -->
                    <el-form-item v-if="brandId == 29 || brandId == 32 || brandId == 33 || brandId == 34" label="设 备 密 码:" label-width="auto" prop="devicePassword">
                        <el-input size="mini" v-model="addBrandsForm.params.devicePassword" clearable style="width:250px" show-password></el-input>
                    </el-form-item>
                    <!-- <el-form-item v-if="brandId == 23" label="拆分:" label-width="auto" prop="devicePassword">
                        <el-select v-model="addBrandsForm.params.ifSplit" size="mini" style="width:250px">
                            <el-option
                                v-for="item in Split"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item v-if="addBrandsForm.params.ifSplit === 1" label="拆分:" label-width="auto" prop="devicePassword">
                        <el-select v-model="addBrandsForm.params.ifSplit" size="mini" style="width:250px">
                            <el-option
                                v-for="item in Split"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item> -->
                </el-form>
                <div slot="footer">
                    <el-button @click="dialogTianJiaXinSheBei = false">取 消</el-button>
                    <el-button type="primary" @click="saveNewEq" v-if="brandId == 22">保 存</el-button>
                    <el-button type="primary" @click="getMusicHostDevice" v-else-if="brandId == 29">确定</el-button>
                    <el-button type="primary" @click="selectRooms" v-else-if="[30, 31, 32, 33, 34, 23, 35].indexOf(brandId) > -1">绑定房间</el-button>
                </div>
            </el-dialog>
            <el-dialog v-dialogDrag title="详细添加设备窗口" v-loading="musicHost.loading" :visible.sync="musicHost.dialogMusicHost" :close-on-click-modal="false" width="720px">
                <el-table :data="musicHost.dataList" size="mini" height="400px" highlight-current-row>
                    <el-table-column prop="devicemac" label="mac地址" min-width="80px"></el-table-column>
                    <el-table-column prop="devicename" label="设备名称" min-width="60px"></el-table-column>
                    <el-table-column prop="devicetoken" label="token" min-width="100px"></el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="musicHost.dialogMusicHost = false">取 消</el-button>
                    <el-button type="primary" @click="addMusicHost">保 存</el-button>
                </span>
            </el-dialog>
            <el-dialog
                v-dialogDrag 
                title="绑定房间" 
                v-loading="faceDevice.loading" 
                :visible.sync="faceDevice.dialogAddFaceDevice" 
                :close-on-click-modal="false" 
                @close="closeDialogAddFaceDevice" 
                width="600px">
                <InstallationAddress @func="getMsgFormSon" v-if="installationAddress"></InstallationAddress>
                <div slot="footer">
                    <el-button @click="faceDevice.dialogAddFaceDevice = false">取 消</el-button>
                    <el-button type="primary" @click="addFaceDevice">确定</el-button>
                </div>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('修改设备类型')" size="mini" @click="xiuGaiClick">修改设备类型</el-button>
            <el-dialog v-dialogDrag title="修改设备类型" v-loading="loading2" :visible.sync="dialogXiuGaiSheBeiLeiXing" :close-on-click-modal="false" width="400px">
                修改设备类型：<el-cascader 
                                v-model="defaultType" 
                                size="mini" 
                                :options="leixingList" 
                                @change="xiuGaiChange" 
                                filterable 
                                :props="{ expandTrigger: 'hover' }" 
                                style="width:60%">
                            </el-cascader>
                <div slot="footer">
                    <el-button @click="dialogXiuGaiSheBeiLeiXing = false">取 消</el-button>
                    <el-button type="primary" @click="xiuGaiSave">保 存</el-button>
                </div>
            </el-dialog>
            <!-- 修改设备名称 -->
            <el-button type="primary" size="mini" @click="updateDeviceNameClick">修改设备名称</el-button>
            <el-dialog
              v-dialogDrag
              title="修改设备名称"
              :visible.sync="isUpdateDeviceName"
              width="30%"
              :close-on-click-modal="false" >
              <div style="margin-bottom:30px">
                  <p>设备名称:<el-input size="mini" clearable style="width:250px" disabled v-model="form.params.devNickname"></el-input></p>
                  <p>设备SN码:<el-input size="mini" clearable style="width:250px" disabled v-model="form.params.devSn"></el-input></p>
              </div>
              <p>新设备名称:<el-input size="mini" clearable style="width:250px" v-model="newDevNickName"></el-input></p>
              <span slot="footer" class="dialog-footer">
                <el-button @click="isUpdateDeviceName = false">取 消</el-button>
                <el-button type="primary" @click="updateDeviceName">确 定 修 改</el-button>
              </span>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('关联安装位置')" size="mini" @click="anZhuangClick">关联安装位置</el-button>
            <el-dialog v-dialogDrag title="设置安装位置" v-loading="loading3" :visible.sync="dialogSheBeiAnZhuangWeiZhi" :close-on-click-modal="false" width="400px">
                <el-form :model="qjPlace" label-width="150px">
                    <el-form-item label="设置安装位置：">
                        <el-select v-model="qjPlace.params.anzhuangweizhi" @change="selAddChange" size="mini" allow-create filterable clearable style="width:70%">
                            <el-option v-for="item in qjPlace.place" :key="item.idpId" :label="item.idpPlace" :value="item.idpPlace"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button @click="dialogSheBeiAnZhuangWeiZhi = false">取 消</el-button>
                    <el-button type="primary" @click="putPlace">保 存</el-button>
                </div>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('设置安装地址')" size="mini" @click="bangDingSheBei">设置安装地址</el-button>
            <el-dialog v-dialogDrag title="绑定" v-loading="loading4" :visible.sync="dialogQwe" :close-on-click-modal="false" @close="installationAddress = false" width="800px">
                <InstallationAddress @func="getMsgFormSon" v-if="installationAddress"></InstallationAddress>
                <div slot="footer">
                    <el-button @click="dialogQwe = false">取 消</el-button>
                    <el-button type="primary" @click="bangDingSave">保 存</el-button>
                </div>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('设置情景模式')" size="mini" @click="qingjingmoshi">设置情景模式</el-button>
            <el-button type="primary" v-if="autheMixin('定时任务管理')" size="mini" @click="dingshirenwu">定时任务管理</el-button>
            <el-button type="primary" v-if="autheMixin('设备集控')" size="mini" @click="equipmentWith = true">设备集控</el-button>
            <el-dialog v-dialogDrag :visible.sync="equipmentWith" :close-on-click-modal="false" width="1000px" class="equipmentWith">
                <el-row style="background-color: #02192e">
                    <!--          左边switch-->
                    <el-col :span="12">
                        <el-row>
                            <el-col :span="12">
                                <div class="topLeft">
                                    <p>开关控制</p>
                                    <div class="switch" ref="highlighted2"></div>
                                    <el-button class="controlButton" @click="socketPointOpen" ref="highlighted">全开</el-button>
                                    <el-button class="controlButton" @click="socketPointClose" ref="highlighted3">全关</el-button>
                                    <div class="visualization-setting__i" @click="settingClick(1)">
                                            <i class="el-icon-setting fz-24"></i>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="topLeft">
                                    <p>灯光控制</p>
                                    <div class="switch" ref="highlightedLight2"></div>
                                    <el-button class="controlButton" @click="lightOpen" ref="highlightedLight">全开</el-button>
                                    <el-button class="controlButton" @click="lightCloes" ref="highlightedLight3">全关</el-button>
                                    <div class="visualization-setting__i" @click="settingClick(2)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <div class="topLeft" style="margin-top: 0;">
                                    <p>窗帘控制</p>
                                    <div class="switch" ref="highlightedCurtain2"></div>
                                    <el-button class="controlButton" @click="curtainOpen" ref="highlightedCurtain">全开</el-button>
                                    <el-button class="controlButton" @click="curtainCloes" ref="highlightedCurtain3">全关</el-button>
                                    <div class="visualization-setting__i" @click="settingClick(3)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="topLeft" style="margin-top: 0;">
                                    <p>空调控制</p>
                                    <div class="switch" ref="highlightedAir2"></div>
                                    <el-button class="controlButton" @click="conditionerOpen" ref="highlightedAir">全开</el-button>
                                    <el-button class="controlButton" @click="conditionerCloes" ref="highlightedAir3">全关</el-button>
                                    <div class="visualization-setting__i" @click="settingClick(4)">
                                            <i class="el-icon-setting fz-24"></i>
                                        </div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                    <!--          右边进度条-->
                    <el-col :span="12">
                        <el-row>
                            <div class="location">
                                <el-col :span="19">
                                    <el-input v-model="selectLocationInput" type="textarea" :rows="3" readonly placeholder="请点击右测按键，添加需要集中控制的位置"></el-input>
                                </el-col>
                                <el-col :span="5">
                                    <div class="locationButton">
                                        <el-button @click="addLocation" size="medium">添加</el-button>
                                        <el-button @click="addLocation" size="medium">修改</el-button>
                                    </div>
                                </el-col>
                            </div>
                        </el-row>
                        <el-row>
                            <el-col :span="12" style="text-align: center">
                                <el-progress :percentage="percentage3" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                <div class="progressButton">
                                    <el-button icon="el-icon-minus" @click="decrease3" circle></el-button>
                                    <span>亮度</span>
                                    <el-button icon="el-icon-plus" @click="increase3" circle></el-button>
                                </div>
                            </el-col>
                            <el-col :span="12" style="text-align: center">
                                <el-progress :percentage="percentage4" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                <div class="progressButton">
                                    <el-button icon="el-icon-minus" @click="decrease4" circle></el-button>
                                    <span>色温</span>
                                    <el-button icon="el-icon-plus" @click="increase4" circle></el-button>
                                </div>
                            </el-col>
                        
                        </el-row>
                         <el-row>
                                <el-col :span="12" style="text-align: center">
                                <el-progress :percentage="percentage" :format="unit" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                <div class="progressButton">
                                    <el-button icon="el-icon-minus" @click="decrease" circle></el-button>
                                    <span>风速</span>
                                    <el-button icon="el-icon-plus" @click="increase" circle></el-button>
                                </div>
                            </el-col>
                            <el-col :span="12" style="text-align: center">
                                <el-progress :percentage="percentage2" :format="unit2" :text-inside="true" :stroke-width="40" class="progressBar"></el-progress>
                                <div class="progressButton">
                                    <el-button icon="el-icon-minus" @click="decrease2" circle></el-button>
                                    <span>温度</span>
                                    <el-button icon="el-icon-plus" @click="increase2" circle></el-button>
                                </div>
                            </el-col>
                        </el-row> 
                        <el-row style="background-color: #1d364f;">
                            <div class="model">
                                <div class="modelChild">
                                    <!--                  <div class="dot" ref="pointRefrigeration2"></div>-->
                                    <p>制冷</p>
                                    <div class="bigDot" @click="pointRefrigeration" ref="pointRefrigeration">
                                        <el-image :src="img.url1" fit="fill"></el-image>
                                    </div>
                                </div>
                                <div class="modelChild">
                                    <!--                  <div class="dot" ref="pointHeating2"></div>-->
                                    <p>制热</p>
                                    <div class="bigDot" @click="pointHeating" ref="pointHeating">
                                        <el-image :src="img.url2" fit="fill"></el-image>
                                    </div>
                                </div>
                                <div class="modelChild">
                                    <!--                  <div class="dot" ref="pointAutomatic2"></div>-->
                                    <p>自动</p>
                                    <div class="bigDot" @click="pointAutomatic" ref="pointAutomatic">
                                        <el-image :src="img.url3" fit="fill"></el-image>
                                    </div>
                                </div>
                                <div class="modelChild">
                                    <!--                  <div class="dot" ref="pointArefaction2"></div>-->
                                    <p>除湿</p>
                                    <div class="bigDot" @click="pointArefaction" ref="pointArefaction">
                                        <el-image :src="img.url4" fit="fill"></el-image>
                                    </div>
                                </div>
                                <div class="modelChild">
                                    <!--                  <div class="dot" ref="pointAeration2"></div>-->
                                    <p>送风</p>
                                    <div class="bigDot" @click="pointAeration" ref="pointAeration">
                                        <el-image :src="img.url5" fit="fill"></el-image>
                                    </div>
                                </div>
                            </div>
                        </el-row>
                    </el-col>
                </el-row>
            </el-dialog>
            <el-dialog v-dialogDrag title="设备详情" :visible.sync="deviceInfo" :close-on-click-modal="false" width="900px">
                <el-table :data="deviceInfoData" :key="keyData" style="width:100%;">
                    <el-table-column prop="hsAddCommunity" label="安装地址" min-width="100px"></el-table-column>
                    <el-table-column prop="devNickname" label="设备名称" min-width="50px"></el-table-column>
                    <el-table-column prop="result" label="结果" min-width="40px"></el-table-column>
                    <el-table-column prop="State" label="状态" min-width="100px"></el-table-column>
                    <el-table-column label="操作" min-width="80px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.result == undefined"></div>
                            <el-button @click="retry(scope.row)" v-else v-show="scope.row.result != '成功'" type="danger" size="mini">重试</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog v-dialogDrag :title="locationTitle" :visible.sync="locationDialog" :close-on-click-modal="false" width="900px">
                <el-row class="transferStyle">
                    <!--穿梭框-->
                    <el-col>
                        <el-transfer v-model="selectLocation" :props="{key: 'id',label: 'value'}" :data="collectiveList"></el-transfer>
                    </el-col>
                </el-row>
                <div slot="footer">
                    <el-button @click="locationDialog = false">取 消</el-button>
                    <el-button type="primary" @click="locationDialogSave">保 存</el-button>
                </div>
            </el-dialog>
            <el-button
                type="primary"
                v-if="autheMixin('天猫设置')"
                size="mini"
                @click="
                    equipmentPermissions.dialogPermissions = true;
                    getElvesData();
                ">天猫设置
            </el-button>
            <el-dialog
                v-dialogDrag
                title="天猫设置"
                :visible.sync="equipmentPermissions.dialogPermissions"
                @close="DialogPermissionsClose"
                :close-on-click-modal="false"
                width="70%"
            >
                <!-- DevicePower -->
                <device-power @addevice="innerVisible = true" ref="device1"></device-power>
                <el-dialog
                    v-dialogDrag
                    width="60%"
                    title="添加设备"
                    :visible.sync="innerVisible"
                    :destroy-on-close="true"
                    v-if="innerVisible"
                    append-to-body
                    :close-on-click-modal="false"
                    :before-close="inineclose"
                >
                    <!-- DevicePower -->
                    <device-power :mold="1" ref="device2"></device-power>
                </el-dialog>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('添加管理卡')" size="mini" @click="openDialogBatchCard(0)">添加管理卡</el-button>
            <el-button type="primary" v-if="autheMixin('注销管理卡')" size="mini" @click="openDialogBatchCard(1)">注销管理卡</el-button>
            <el-dialog v-dialogDrag :title="batchCard.title" @close="dialogBatchCardClose()" :visible.sync="batchCard.dialogBatchCard" :close-on-click-modal="false" width="470px">
                <el-form :model="batchCard" :rules="batchCard.rules" ref="checkBatchCard">
                    <el-row v-if="batchCard.type">
                        <el-form-item prop="cardId" style="margin-left: 105px">
                            <el-select v-model="batchCard.cardId" filterable clearable placeholder="请选择">
                                <el-option v-for="(item, index) in batchCard.managementCards" :key="index" :label="item.suStaffName" :value="item.jdcCardId">
                                    <span style="float: left">{{ item.suStaffName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.jdcCardId }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row v-else>
                        <el-col :span="10">
                            <el-form-item label="用户" label-width="50px" prop="userId">
                                <el-cascader :options="userNameList" @change="selectUser" v-model="batchCard.user" :props="{ expandTrigger: 'hover' }" :show-all-levels="false" size="mini" class="screenInput" clearable>
                                </el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="14">
                            <el-form-item label="授权卡号" label-width="90px" prop="cardId">
                                <el-input v-model="batchCard.cardId" size="mini"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item style="text-align: center; margin-top: 15px;">
                            <el-row style="text-align: center;">
                                <el-button @click="batchCard.dialogBatchCard = false">取 消</el-button>
                                <el-button type="primary" @click="doBatchCard()">确 定</el-button>
                            </el-row>
                        </el-form-item>
                    </el-row>
                </el-form>
            </el-dialog>
            <!--设备拆分开始-->
            <el-button type="primary" size="mini" @click="openEquipmentSplit" v-if="autheMixin('设备拆分')">设备拆分</el-button>
            <el-dialog v-dialogDrag title="多路设备拆分" :visible.sync="equipmentSplit.dialogEquipmentSplit" @close="closeEquipmentSplit" width="450px">
                <el-divider content-position="left">已拆分路数</el-divider>
                <el-form :model="equipmentSplit" :rules="equipmentSplit.rules" ref="checkDevNickname">
                    <el-form-item v-for="(item, i) in equipmentSplit.dataList" :key="i" :label="`${item.devRoad + 1}路名称`" label-width="65px">
                        <el-input v-model="item.devNickname" size="mini" disabled></el-input>
                    </el-form-item>
                    <el-divider content-position="left">剩余可拆分路数</el-divider>
                    <el-form-item v-for="(item, i) in equipmentSplit.paramList" :key="i" :label="`${item.devRoad + 1}路名称`" label-width="65px" prop="devNickname">
                        <el-input v-model="item.devNickname" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: center; margin-top: 15px;">
                        <el-row style="text-align: center;">
                            <el-button @click="equipmentSplit.dialogEquipmentSplit = false">取 消</el-button>
                            <el-button type="primary" @click="doEquipmentSplit">添加</el-button>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!--设备拆分结束-->
            <!--打印二维码开始-->
            <el-button type="primary" v-if="autheMixin('打印设备二维码')" size="mini" @click="qrcode">打印设备二维码</el-button>
            <el-button type="primary" size="mini" @click="addVirtualTelecontrol" v-if="autheMixin('添加虚拟集控')">添加虚拟集控</el-button>
            <el-dialog
                title="添加虚拟集控"
                :visible.sync="virtualTelecontrol.dialogvirtualTelecontrol"
                width="450px"
                destroy-on-close
            >
                <el-form :model="virtualTelecontrol" ref="virtualTelecontrol" label-width="150px">
                    <el-form-item label="设备名称：">
                        <el-input
                            size="mini"
                            v-model="virtualTelecontrol.name"
                            style="width: 70%;"
                            placeholder="请输入设备名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="所属房间：">
                        <el-input
                            size="mini"
                            v-model="virtualTelecontrol.address"
                            style="width: 70%;"
                            readonly
                            @click.native="selectRoom('选择所属房间')"
                            placeholder="点击选择所属房间"
                        ></el-input> 
                    </el-form-item>
                    <!-- 任务归属对话框开始 -->
                    <select-address
                        v-if="taskAffiliationDialog"
                        :isDialogShow="taskAffiliationDialog"
                        :isTitle="taskAffiliationDialogTitle"
                        @close="selectRoomDblclick"
                    >
                    </select-address>
                    <!-- 任务归属对话框结束 -->
                    <el-form-item label="设备类型：">
                        <!-- <el-select
                            v-model="virtualTelecontrol.subtype"
                            @change="selAddChange"
                            size="mini"
                            allow-create
                            filterable
                            clearable
                            style="width:70%"
                        >
                            <el-option
                                v-for="item in virtualTelecontrol.deviceType"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select> -->
                        <div class="block">
                          <el-cascader
                            v-model="virtualTelecontrol.subtype"
                            :options="virtualTelecontrol.deviceType"
                            :props="{ expandTrigger: 'hover' }"
                            clearable
                            size="mini"
                            style="width:70%"
                            @change="selAddChange"></el-cascader>
                        </div>
                    </el-form-item>
                    <el-form-item label="所属网关：">
                        <el-select
                            v-model="virtualTelecontrol.mac"
                            size="mini"
                            allow-create
                            filterable
                            clearable
                            style="width:70%"
                        >
                            <el-option
                                v-for="item in virtualTelecontrol.gateway"
                                :key="item.devBrandId"
                                :label="item.devNickname"
                                :value="item.devAuthSecret"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                        label="sn码：" 
                        prop="sn" 
                        :rules="[
                            {validator: verifySNCode, trigger: 'blur'}
                        ]"
                    >
                        C15359
                        <el-input 
                            v-model="virtualTelecontrol.sn"
                            size="mini"
                            style="width:50%"
                            maxlength="8"
                            show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="virtualTelecontrolSubmit">提交</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-button type="primary" v-if="autheMixin('酒店客控')" size="mini" @click="visualization">酒店客控</el-button>
            <el-button type="primary" v-if="autheMixin('房间控制')" size="mini" @click="roomControl">房间控制</el-button>
            <el-dialog v-dialogDrag title="打印设备二维码" :visible.sync="qr.dialogQr" :close-on-click-modal="false" width="850px">
                <el-row id="printTest">
                    <el-col :span="6" align="center" v-for="(item,index) in qr.qrArr" :key="index" class="qr-code">
                        <vue-qr :text="item.qrText" :margin='0' :size="120" colorLight="#fff" colorDark="#333"></vue-qr>
                        <p>{{item.hsAddCommunity}}</p>
                        <p>{{item.qrText}}</p>
                        <p>{{item.devNickname}}</p>
                    </el-col>
                </el-row>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="qr.dialogQr = false">取 消</el-button>
                    <el-button type="primary" v-print="'#printTest'">打印</el-button>
                </div>
            </el-dialog>
            <!--打印二维码结束-->
            <!-- 设备模式排序 -->
            <el-button type="primary" size="mini" @click="sceneModeClick">设备排序</el-button>
            <el-dialog
                title="设备排序"
                :visible.sync="sceneMode.isSceneMode"
                width="50%"
                destroy-on-close
            >
                <el-form>
                    <el-form-item label="设备位置：" prop="affiliation" >
                        <el-input v-model="forms.affiliation"  @click.native="seleceRoom" placeholder="点击选择设备位置" size="mini" style="width:250px"></el-input> 
                        <el-dialog v-dialogDrag title="选择其他房间" :visible.sync="installationAddress" :close-on-click-modal="false" @close="installationAddressClose" width="800px" append-to-body>
                            <InstallationAddress ref="installationAddress" @rowDB="confirmSelece" v-if="installationAddress" :isDuoXuan="isDuoXuan=false"/>
                        </el-dialog>
                    </el-form-item>
                </el-form>
                <div v-if="forms.hsId">
                    <el-table
                      v-loading="sceneMode.loading"
                      :data="sceneMode.tableData"
                      row-key="address"
                      border
                      style="width: 100%"
                      height="400px"
                      ref="tableList">
                      <el-table-column show-overflow-tooltip prop="devOrderId" label="序号" width="50" align="center"></el-table-column>
                      <el-table-column show-overflow-tooltip aligen="center"  label="安装位置">
                        <template slot-scope="scope">
                            {{ scope.row.hsAddCommunity }} {{ scope.row.hsAddFloor }} {{ scope.row.hsAddDoorplateno }} 
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip prop="devNickname" label="设备名称" aligen="center"></el-table-column>
                      <el-table-column label="操作" align="center" width='150px'>
                        <template slot-scope="scope">
                             <el-tooltip class="item" effect="dark" content="置顶" placement="left">
                              <ToTop theme="filled" size="30" fill="#409EFF" @click="baocun(scope)" class="totop"/>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="收藏" placement="right">
                              <Like :theme="scope.row.devCollect?'filled':'outline'" size="30" fill="#409EFF" @click="collectionStop(scope.$index)" class="collection"/>
                            </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-row style="margin-top:24px">
                        <el-col :span="24" align="right">
                            <el-button type="primary" @click="sceneModeSubmitForm">保存</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-dialog>
            <!-- 排序方式 -->
            <el-popover placement="bottom" width="156">
                <el-button slot="reference" size="mini">
                    <span>排序方式</span>
                    <i class="el-icon-sort el-icon--right"></i>
                </el-button>
                <el-button-group>
                    <el-button :type="form.sortGp.descBtn" @click="sortPopoverBtnGp('descBtn',1)" size="mini" style="width: 78px;">正序<i class="el-icon-sort-down el-icon--right"></i></el-button>
                    <el-button :type="form.sortGp.ascBtn" @click="sortPopoverBtnGp('ascBtn',2)" size="mini" style="width: 78px;">倒序<i class="el-icon-sort-up el-icon--right"></i></el-button>
                </el-button-group>
                <div>
                    <p><el-button :type="btns.lp" class="btns"  @click="sortord('lp',1)" size="mini" style="width: 100%;">楼盘</el-button></p>
                    <p><el-button :type="btns.ld" class="btns"  @click="sortord('ld',2)" size="mini" style="width: 100%;">楼栋</el-button></p>
                    <p><el-button :type="btns.mph" class="btns"  @click="sortord('mph',3)" size="mini" style="width: 100%;">门牌号</el-button></p>
                    <p><el-button :type="btns.sblx" class="btns"  @click="sortord('sblx',4)" size="mini" style="width: 100%;">设备类型</el-button></p>
                    <p><el-button :type="btns.djsj" class="btns" @click="sortord('djsj',5)" size="mini" style="width: 100%;">登记时间</el-button></p>
                </div>
            </el-popover>
        </el-row>

        <el-row style="padding: 5px 5px 5px 0;" class="text-weight">
            <!-- 筛选栏 -->
            安装地址：<el-input size="mini" v-model="form.params.hsAddCommunity" @change="AddChange" clearable style="width:15%"></el-input>
            设备：<el-cascader size="mini" :options="leixingList" filterable @change="cachange" :props="{ checkStrictly: true }" clearable style="width:15%"></el-cascader>&nbsp;&nbsp;
            名称：<el-input size="mini" v-model="form.params.deviceName" @change="nameChange" clearable style="width:15%"></el-input>&nbsp;&nbsp;
            SN：<el-input size="mini" v-model="form.params.deviceSN" @change="SNChange" clearable style="width:15%"></el-input>&nbsp;&nbsp;
            <el-button type="primary" size="mini" class="shuaxin" @click="zhuangTaiShuaXin">刷新</el-button>
            <el-select v-model="refreshState" @change="timeRefresh" size="mini" class="timeshuax">
                <el-option label="3s刷新" value='3s' v-if="autheMixin('刷新')"></el-option>
                <el-option label="5s刷新" value='5s' v-if="autheMixin('刷新')"></el-option>
                <el-option label="手动刷新" value="手动"></el-option>
            </el-select>
        </el-row>
    </div>

    <EquipmentTable
        ref="equipmentTable"
        :form="form"
        @update="getAllSheBei"
    >
        <!--数据表-->
        <el-table 
            :data="form.dataList" 
            :row-class-name="deviceTable" 
            type="index" 
            highlight-current-row 
            v-loading="form.loading" 
            @row-click="rowClick" 
            @row-dblclick="caoZuoClick" 
            @selection-change="rowSel" 
            @select="equipmentSelect" 
            @select-all="equipmentSelectAll" 
            ref="checkTable" 
            :height='tableHeight'
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="hsAddCommunity" show-overflow-tooltip label="安装地址" min-width="150px"></el-table-column>
            <el-table-column prop="idftName" label="设备" show-overflow-tooltip min-width="80px"></el-table-column>
            <el-table-column prop="idstName" label="型号" show-overflow-tooltip min-width="80px"></el-table-column>
            <el-table-column prop="secondaryTypeName" label="位置" show-overflow-tooltip min-width="90px"></el-table-column>
            <el-table-column prop="devNickname" show-overflow-tooltip label="名称" min-width="80px"></el-table-column>
            <el-table-column prop="devSn" label="SN" min-width="120px"></el-table-column>
            <el-table-column prop="stateInformation" label="状态" show-overflow-tooltip min-width="110px"></el-table-column>
            <el-table-column prop="devAuthSecret" label="所挂网关" min-width="120px"></el-table-column>
        </el-table>
        <el-pagination 
            layout="total, sizes, prev, pager, next, jumper" 
            :page-count="form.pages" 
            :page-size="form.limit" 
            :total="form.total" 
            background 
            align='center' 
            :current-page="form.cursor"
            @current-change="currentChange" 
            @size-change="sizeChange" 
            :page-sizes="[5,10,20,40,100]">
        </el-pagination>
    </EquipmentTable>
     <SettingDialog 
        ref="settingDialog"
        :dialogVisible="settingdialogVisible" 
        @handleSettingDialogClose="handleSettingDialogClose"/>
</div>
</template>

<script>
import EquipmentTable from '@/components/EquipmentTable'
import InstallationAddress from '../../components/equipmentChild/installationAddress' //设置设置安装地址
import DevicePower from "./components/DevicePermissions/index"; //引入天猫设置
import selectAddress from '@/components/selectAddress/selectAddress.vue';
import SettingDialog from './visualization-cpns/settingDialog';
import { ToTop,Like } from '@icon-park/vue'
import Sortable from 'sortablejs';
import { sceneList,sceneUpdateOrderID } from '@/api/scene.js'
import { mapState } from 'vuex';
import { autheEquipmentListMixin } from '@/mixins/autheMixins'

let timer
export default {
    name: 'equipmentList',
    mixins: [autheEquipmentListMixin],
    components: {
        EquipmentTable,
        InstallationAddress,
        DevicePower,
        selectAddress,
        SettingDialog,
        ToTop,
        Like
    },
    data() {
        return {
            webSocketTime:null,
            webSocket:null,
            newDevNickName:'',
            forms:{
              affiliation:'',
              hsId:'',
              hsId2:''
            },
            sceneMode:{
                isSceneMode:false,//弹出层
                tableData:[],
                loading:false,
                roomData:{}
            },
            typeID:[],
            tableHeight: window.innerHeight - 350,
            isUpdateDeviceName:false,//修改设备名称dialog
            installationAddress: false,
            innerVisible: false,
            keyData: 0,
            deviceInfoData: [], //设备详情数组
            deviceInfo: false, //设备详情
            collective: {
                devState: '', //开关
                devType: '', //设备类型
                devAirPattern: '制冷', //空调模式
                devAirTemperature: 26, //空调温度
                devAirWindSpeed: '中', //空调风速
                controlState: [], //集控设备状态
            },
            conditionerState: '', //空调开关状态
            lamplightState: '', //灯光开关状态
            img: {
                url1: require('../../assets/icon-1.png'),
                url2: require('../../assets/icon-2.png'),
                url3: require('../../assets/icon-3.png'),
                url4: require('../../assets/icon-4.png'),
                url5: require('../../assets/icon-5.png')
            },
            equipmentWith: false,
            percentage: 0,
            percentage2: 0,
            percentage3: 0,
            percentage4: 0,
            refreshState: '手动', //定时刷新状态
            removeTime: null, //定时器初始值
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            dialogQwe: false,
            dialogTianJiaXinSheBei: false,
            dialogSheBeiAnZhuangWeiZhi: false,
            dialogXiuGaiSheBeiLeiXing: false,
            qjPlace: { //情景位置数据
                params: {
                    xinzenganzhuangweizhi: '',
                    anzhuangweizhi: '',
                },
                place: '',
            },
            row: {
                fsid: '',
                lsid: '',
                shouquan: '',
                kahao: '',
            },
            Split:[{value: 1,label: '拆分'}, {value: 0,label: '不拆分'}],
            addBrandsForm: { //添加新设备
                params: {
                    secondType: '',
                    deviceKey: '',
                    deviceName: '',
                    deviceAccount: '',
                    devicePassword: '',
                    deviceUserName: '',
                    portNumber:'',//端口号
                    ip: '',
                    ifSplit:'',//是否拆分
                    installPosition:'',//安装位置
                },
                rules: { //验证规则
                    secondType: [{
                        required: true,
                        message: '设备类型不能为空',
                        trigger: 'blur',
                    }, ],
                    deviceKey: [{
                        required: true,
                        message: '设备序列号不能为空',
                        trigger: 'blur',
                    }, ],
                    deviceName: [{
                        required: true,
                        message: '设备名称不能为空',
                        trigger: 'blur',
                    }, ],
                    deviceAccount: [{
                        required: true,
                        message: '设备账号不能为空',
                        trigger: 'blur',
                    }, ],
                    devicePassword: [{
                        required: true,
                        message: '设备密码不能为空',
                        trigger: 'blur',
                    }, ],
                    deviceUserName: [{
                        required: true,
                        message: '设备用户名不能为空',
                        trigger: 'blur',
                    },],
                    ip: [{
                        required: true,
                        message: '设备ip不能为空',
                        trigger: 'blur',
                    }, ]
                },
            },
            addBrandsForm2:{
                secondType: '',
                deviceKey: '',
                deviceName: '',
                deviceAccount: '',
                devicePassword: '',
                deviceUserName: '',
                portNumber:'',//端口号
                ip: '',
                ifSplit:'',//是否拆分
                installPosition:'',//安装位置
            },
            btns:{
                lp: '',
                ld: '',
                mph:'',
                sblx:'',
                djsj:'primary'
            },
            form: { //设备列表数据
                sortGp: {//正倒序
                    ascBtn: 'primary',
                    descBtn: '',
                },
                params: {
                    deviceName: '',
                    deviceSN: '',
                    idftName: '',
                    hsAddCommunity: '',
                    devFirstType: '',
                    devSecondType: '',
                    orderRule: 2, //排序规则
                    orderWord: 5 //1楼盘 2楼栋 3门牌号 4设备类型
                    //jhcName: ''
                },
                dataList: [],
                selIdpId: '',
                clickrow: null,
                selrows: '',
                loading: false,
                cursor: 1, //当前页面
                limit: 10, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            equipmentPermissions: { //设备权限
                dialogPermissions: false,
                dialogAddEquipment: false,
                defaultSelect: "", //删除 - 默认导航条选中的路径
                defaultSelect2: "", //开始 - 默认导航条选中的路径
                selectionIndexPath: "", //删除 - 导航条选中的路径
                selectionIndexPath2: "", //添加 - 导航条选中的路径
                asideList: [], //设备位置和设备类型列表
                asideConfigHave: [], //已添加设备配置信息
                asideConfigNoHave: [], //未添加设备配置信息
                defaultEquipment: [], //进入Dialog默认显示
                selectionEquipment: [], //选中设备
            },
            selCity: {
                bdclickrow: ''
            },
            changestatus: { //修改设备状态数据
                nowStates: {},
                devAirPattern: "",
                devAirSweepingWind: "",
                devAirTemperature: "",
                devAirWindSpeed: "",
                devBrightness: "1",
                devColorTemperature: "1",
                devId: "",
                devLowerLimitLumen: "",
                devLumenSpacing: "",
                devNumber: "",
                devPercentage: "",
                devState: "",
                devUpperLimitLumen: "",
                temperature: '',
                devAirFactors: '',
                devAirCurrent: '',
                devAirVoltage: '',
                devAirPower: '',
                devAirElectricity: '',
                devAirTemperature2: '',
                devAirProtectTemperature: '',
                devSn: '',
                jourDeviceId: '',
                authorizeType: '',
                jdcCardId: '',
                houseAddress: '',
                jdcPassword: '',
                jdcDeadlineTime: '',
                jdcMaxUnlockingTimes: null,
                jdcHsId: null,
                jdcUserId: null,
                encryptMode: null,
                startTime: '',
                sendMessage: 0,
                csRoad: '',
                csRoad2: '',
                csRoad3: '',
                whiteBrightness: 0, //白光亮度
                colorTemperature: 0, //白光色温
                whiteMode: 0, //模式
                rgbSpeed: 0, //彩光速度
                rgbMode: 0, //彩光模式
                rgbBrightness: 0, //彩光亮度
                rcolorValue: '',
                gcolorValue: '',
                bcolorValue: '',
                percentCurtain: 0,
                type: 1,
            },
            remoteUnlock: { //远程开锁记录
                dataList: [],
                cursor: 1, //当前页面
                limit: 5, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
            },
            defaultType: [],
            brandId: '',
            brandsList: [],
            leixingList: [],
            selectLocation: [], //选择集控位置设备
            locationTitle: '', //位置添加标题
            locationDialog: false,
            selectLocationInput: '',
            //集控
            collectiveList: [], //集控设备列表
            batchCard: {
                dialogBatchCard: false,
                title: "添加管理卡",
                loading: false,
                cardId: '',
                user: '',
                userId: '',
                type: 0,
                managementCards: [],
                rules: {
                    cardId: [{
                        required: true,
                        message: '授权卡号不能为空',
                        trigger: 'blur',
                    }],
                    userId: [{
                        required: true,
                        message: '用户不能为空',
                        trigger: 'blur',
                    }]
                }
            },

            //离线写卡
            cardState: false,
            strCard: '',

            //二维码相关
            qr: {
                equipmentSelectArr: [], //用户勾选arr
                qrArr: [],
                dialogQr: false,
                checkAll: []
            },

            //设备拆分
            equipmentSplit: {
                dataList: [],
                paramList: [],
                totalRoads: 2,
                dialogEquipmentSplit: false,
                rules: {
                    devNickname: [{
                        required: true,
                        message: '路数名称不能为空',
                        trigger: 'blur',
                    }, ]
                }
            },


            //背景音乐
            musicHost: {
                loading: false,
                dialogMusicHost: false,
                dataList: []
            },

            //人脸抓拍
            faceDevice: {
                loading: false,
                dialogAddFaceDevice: false,
                typeList: [],
            },

            //添加虚拟遥控器
            virtualTelecontrol:{
                dialogvirtualTelecontrol:false,
                place:[],//所属房间
                deviceType:[{
                    value: '',
                    label: '开关',
                    children: [{
                      value: 9,
                      label: '一位开关集控'
                    }, {
                      value: 12,
                      label: '二位开关集控'
                    }, {
                      value: 15,
                      label: '三位开关集控'
                    }, {
                      value: 18,
                      label: '四位开关集控'
                    }]
                },{
                    value: '',
                    label: '灯光',
                    children: [{
                      value: 10,
                      label: '一位灯光集控'
                    }, {
                      value: 13,
                      label: '二位灯光集控'
                    }, {
                      value: 16,
                      label: '三位灯光集控'
                    }, {
                      value: 19,
                      label: '四位灯光集控'
                    }],
                },{
                    value: '',
                    label: '窗帘',
                    children: [{
                      value: 11,
                      label: '一位窗帘集控'
                    }, {
                      value: 14,
                      label: '二位窗帘集控'
                    }, {
                      value: 17,
                      label: '三位窗帘集控'
                    }, {
                      value: 20,
                      label: '四位窗帘集控'
                    }],
                }],//设备类型
                gateway:[],//所属网关
                name:'',//设备名称
                address:'',//所属房间value
                hsId:'',//所属房间hsId
                subtype:[],//设备类型value
                mac:'',//所属网关value
                sn:'',//设备sn
            },
            taskAffiliationDialog:false,
            taskAffiliationDialogTitle:'',
            settingdialogVisible:false
        }
    },
    computed: {
        ...mapState(['pageAuth', 'userNameList']),
        toUpper: {
            get() {
                return this.addBrandsForm.params.deviceKey;
            },
            set(val) {
                if([32].indexOf(this.brandId) > -1 || [33].indexOf(this.brandId) > -1 || [34].indexOf(this.brandId) > -1){
                    this.addBrandsForm.params.deviceKey = val.toLowerCase();
                }else if([22,23].indexOf(this.brandId) > -1){
                    this.addBrandsForm.params.deviceKey = val.toUpperCase()
                }
            }
        }
    },
    created() {
        if (!this.pageAuth['/equipmentList']) {
            this.$router.replace('/notfind')
        } else {
            this.getAllSheBei().then(()=>{
                this.buildWebSocket()
            })
        }
        
    },
    updated() {
        this.tableHeight = window.innerHeight - this.$refs.wngRow.$el.offsetHeight - 210
    },
    mounted() {
        this.getAllLeiXing()
    },
    destroyed(){
        this.webSocketOnclose()
    },
    methods: {
        // 123
        buildWebSocket(){
            let protocol = location.protocol.split(':')[0] == "https"?'wss':'ws'
            let url = null
            if(process.env.NODE_ENV == 'development' || process.env.VUE_APP_TITLE == 'online'){
                url = `wss://api.86xc.net/wss/channel?token=${sessionStorage.getItem('token')}`
                // url = `${protocol}://172.18.88.130/wss/channel?token=${sessionStorage.getItem('token')}`
            }else{
                url = `${protocol}://${location.host}/wss/channel?token=${sessionStorage.getItem('token')}`
            }
            let webSocket = this.webSocket==null ? new WebSocket(url) : this.webSocket;
            this.webSocket = webSocket;
            // console.log(this.webSocket,'321');
            this.webSocket.onopen = this.webSocketOnopen          // 连接成功建立的回调方法
            this.webSocket.onmessage = this.webSocketOnmessage    //接收到消息的回调方法
            this.webSocket.onclose=this.webSocketOnclose          //关闭TCP连接
        },
        webSocketOnopen(event){
            console.log('WebSocket连接成功')
            this.webSocketSend()
            this.start()
        },
        webSocketOnmessage(event){
            // 设备列表 状态
            console.log("后端发来消息了！",JSON.parse(event.data));
            this.reset();
            if(event && event.data && JSON.parse(event.data)){
                let data =JSON.parse(event.data)?JSON.parse(event.data):{}
                for(let i in this.form.dataList){
                    let item = this.form.dataList[i]
                    let devRoad ='';
                    if(item.devRoad>0&&item.devRoad<10){
                        devRoad=`0${item.devRoad}`
                    }else{
                        devRoad=item.devRoad
                    }
                    if(item.devSn == data.sn[0]){
                        if(devRoad == data.road[0]){
                           this.$set(item,'stateInformation',data.stateInformation);
                        }
                    }
                }
            }
        },
        webSocketOnclose(event){
            // console.log(event);
            console.log('关闭webSocket')
            let send =JSON.stringify({action:'deviceListPushClose'})
            // console.log(send);
            this.webSocket.send(send);
            clearInterval(this.webSocketTime);
            this.webSocket.close();
            this.webSocket = null
        },
        reset() {
            //重置心跳
            clearInterval(this.webSocketTime);
            //重启心跳
            this.start();
        },
        start() {
          //开启心跳
            this.webSocketTime = setInterval(() => {
                this.webSocketSend()
            }, 180000);
          // 3分钟一次
        },
        async webSocketSend(){
            // if(this.webSocket){
            //     let dataList = await this.form.dataList;
            //     // console.log(dataList,'1111');
            //     let sns = dataList.map(item=>{;return item.devSn}).join();
            //     // console.log(sns,'7777');
            //     let send =JSON.stringify({action:'deviceListPush',sns})
            //     // console.log(send,'8888');
            //     this.webSocket.send(send);
            // }
            if(this.webSocket){
                let dataList = await this.form.dataList;
                let sn=[];
                dataList.map(item=>{
                     if(item.devRoad==0){
                       sn.push(item.devSn)
                     }else{
                       let s;
                       let d = item.devRoad<10?`0${item.devRoad}`:item.devRoad
                       s =`${item.devSn}_${d}`
                       sn.push(s);
                     }
                })
                let sns =sn.join();
                // console.log(sns,'sns-666');
                let send =JSON.stringify({action:'deviceListPush',sns})
                this.webSocket.send(send);
            }
        },
        //点击修改设备名称按钮
        updateDeviceNameClick(){
            if (!this.form.clickrow && !this.form.selrows) {
                this.$message.warning('请先选中一条记录')
            }else{
                this.newDevNickName=""
                this.isUpdateDeviceName=true
                this.form.params.devSn=this.form.clickrow.devSn
                this.form.params.devNickname=this.form.clickrow.devNickname
                console.log("this.form.clickrow",this.form.params)
            }
        },
        //点击修改设备名称确定按钮 --调接口
        updateDeviceName(){
            let co = sessionStorage.getItem("co");//公司标识
            if(this.newDevNickName==""  || this.newDevNickName==" "){
                this.$message.warning('设备名称不能为空')
            }else{
                this.loading=true
                this.$axios({
                    method:"post",
                    // url:"https://www.fangzhizun.com/device/Interface/EditDeviceInfo",// (本地测试用 )
                    url:"/common2/deviceInformation/EditDeviceInfo",
                    params:{
                        co:co,
                        sn:this.form.clickrow.devSn,
                        name:this.newDevNickName,
                        place:this.form.clickrow.devSpare2,
                        road:this.form.clickrow.devRoad
                    }
                }).then(res=>{
                    console.log("修改设备名称",res)
                    this.isUpdateDeviceName=false
                    this.$message.success("修改成功")
                    this.loading=false
                    this.getAllSheBei()
                }).catch(err=>{
                    this.isError(err,this)
                })
            }
        },
        handleSettingDialogClose(){
            this.settingdialogVisible = false
        },
        settingClick(flag){
            console.log(1111);
            this.settingdialogVisible = true
            // 触发 设置弹窗 获取数据的函数 flag 集控类型 
            this.$refs['settingDialog'].getSettingInfo(flag,null)
        },
        // 拖拽排序
      rowDrop() {
          this.$nextTick(()=>{
            const tbody = this.$refs.tableList.$el.querySelector(".el-table__body-wrapper tbody");
            console.log("tbody",tbody);
            console.log(tbody.childNodes)
            let that = this
            Sortable.create(tbody,{
              animation: 500,
              draggable:'.el-table__row',
              onEnd({ newIndex, oldIndex }) {
                // 修改data中的数组，
                const targetRow = that.sceneMode.tableData.splice(oldIndex, 1)[0]
                that.sceneMode.tableData.splice(newIndex, 0, targetRow)
                let newArray = that.sceneMode.tableData.splice(0);
                that.sceneMode.tableData = [];
                that.$nextTick(function () {
                  that.sceneMode.tableData = newArray;
                });
              }
            });
          })
        },
        //点击设备模式排序
        sceneModeClick(){
            this.sceneMode.isSceneMode=true
        },
        //点击设备位置显示子组件
        seleceRoom(){
            this.installationAddress = true;
        },
        //设备位置双击事件
        confirmSelece(data){
            this.sceneMode.loading=true
            this.installationAddress = false;
            this.forms.affiliation = data.hsAddCommunity
            this.forms.hsId = data.hsId
            this.getTableList()
            this.rowDrop()
        },
        //设备排序点击保存
        sceneModeSubmitForm(){
            this.sceneMode.loading=true
            var result=[]
            for(var i=0;i<this.sceneMode.tableData.length;i++){
                var obj={}
                obj.jourDeviceId=this.sceneMode.tableData[i].jourDeviceId
                obj.devCollect=this.sceneMode.tableData[i].devCollect
                obj.devOrderId=i+1
                obj.hsId=this.sceneMode.tableData[i].hsId
                result.push(obj)
            }
            this.$axios({
              url:'/common2/devices/updateDevOrderId',
              method:'put',
              data:{
                  result
              }
          }).then(res=>{
              this.$message.success("保存成功")
              this.sceneMode.loading=false
              this.getTableList()
          }).catch(err=>{
              this.isError(err,this)
              this.sceneMode.loading=false
          })
        },
        //根据设备id获取房间信息
        getTableList(){
            this.$axios({
                url:`/common2/devices/listByHsId`,
                method: 'get',
                params:{
                    hsId:this.forms.hsId
                }
            }).then(res=>{
                // console.log("confirmSelece",res)
                this.sceneMode.tableData=res.data.result
                this.sceneMode.loading=false
            }).catch(err=>{
                this.isError(err,this)
                this.sceneMode.loading=false
            }).catch(err=>{
              this.isError(err,this)
              this.sceneMode.loading=false
          })
        },
        //置顶
        baocun(scope){
            let values = this.sceneMode.tableData;//这个是表格数据
	        let value = this.sceneMode.tableData[scope.$index];
	        let res = [value].concat(values.filter(item => item != value));//concat()方法用于连接两个或多个数组
	        console.log(values.filter(item => item != value));
	        this.sceneMode.tableData = res;
        },
        //收藏
        collectionStop(index){
        //   console.log("收藏",this.sceneMode.tableData[index].devCollect)
          this.sceneMode.tableData[index].devCollect = !this.sceneMode.tableData[index].devCollect==false?0:1
        },
        //设备排序close事件
        installationAddressClose(){
            this.sceneMode.roomData={}
        },
        async getAllSheBei() { //获取所有设备
            await this.$axios({
                method: 'get',
                url: '/common2/devices',
                params: {
                    cursor: this.form.cursor,
                    devFirstType: this.form.params.devFirstType,
                    devSecondType: this.form.params.devSecondType,
                    deviceName: this.form.params.deviceName,
                    deviceSN: this.form.params.deviceSN,
                    orderRule: this.form.params.orderRule,
                    orderWord: this.form.params.orderWord,
                    hsAddCommunity: this.form.params.hsAddCommunity,
                    limit: this.form.limit
                }
            }).then(res => {
                this.form.loading = false
                this.form.dataList = res.data.result.records
                if(this.webSocket){
                    this.webSocketSend()
                    this.reset()
                }
                this.form.total = res.data.result.total
                let ids = []
                console.log("获取所有设备:",this.form.dataList)
                for (let i of this.form.dataList) {
                    ids.push(i.jourDeviceId)
                }
                ids = ids.join(",")
                if (ids.length > 0 && ids.toString() != '[]') {
                    this.$refs.equipmentTable.getAllStateB(ids, this.form.dataList)
                }
                this.form.clickrow = ""
                this.form.selrows = ""
                for (let i of this.form.dataList) {
                    let a = i.hsAddCommunity == null || i.hsAddCommunity == undefined ? '' : `${i.hsAddCommunity}\u3000`
                    let b = i.hsAddBuilding == null || i.hsAddBuilding == undefined ? '' : `${i.hsAddBuilding}\u3000`
                    let c = i.hsAddFloor == null || i.hsAddFloor == undefined ? '' : `${i.hsAddFloor}\u3000`
                    let d = i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined ? '' : `${i.hsAddDoorplateno}`
                    i.hsAddCommunity = a + b + c + d
                }
            }).catch(err => {
                this.form.loading = false
                this.isError(err, this)
            })
        },
        rowSel(selection) { //选择框勾选
            var selid = []
            for (var i = 0; i < selection.length; i++) {
                selid.push(selection[i].jourDeviceId)
            }
            this.form.selrows = selid.toString()
        },
        equipmentSelect(selection) {
            this.qr.equipmentSelectArr = selection
        },
        equipmentSelectAll(selection) {
            this.qr.equipmentSelectArr = selection
        },
        deviceTable({ row, rowIndex }) {
            row.index = rowIndex
        },
        rowClick(row) { //选中一行
            this.$refs.equipmentTable.rowClick(row)
        },
        caoZuoClick(row) { //操作设备按钮和表格双击
            this.$refs.equipmentTable.caoZuoClick(row)
            console.log("row:",row)
        },
        currentChange(cursor) { //当前页面
            this.$refs.equipmentTable.currentChange(cursor)
        },
        sizeChange(size) { //页面显示条数改变
            this.$refs.equipmentTable.sizeChange(size)
        },
        sortPopoverBtnGp(key, value) { //正倒排序

            for (let i in this.form.sortGp) {
                if (i == key) {
                    this.form.sortGp[i] = 'primary'
                } else {
                    this.form.sortGp[i] = ''
                }
            }
            this.form.params.orderRule = value
            console.log(this.form.params.orderRule)
            this.getAllSheBei()
        },
        sortord(key,value) { //排序方式
            for(let i in this.btns){
                if(key==i){
                this.btns[i]='primary'
                }else{
                this.btns[i]=''
                }
            }
            this.form.ascBtn=!this.form.ascBtn
            this.form.params.orderWord=value
            console.log(this.form.params.orderWord)
            this.getAllSheBei()
        },
        inineclose(done) {
            this.$refs.device1.reagin()
            done()

            console.log('dialog关闭')
            // this.$refs.device1
        },
        checkAllFun() { //获取表格全部数据
            this.form.loading = true
            return this.$axios({
                method: 'get',
                url: '/common2/devices',
                params: {
                    limit: 100
                }
            }).then(res => {
                this.qr.checkAll = res.data.result.records
                this.form.loading = false
            }).catch(err => {
                this.isError(err, this)
                this.form.loading = false
            })
        },
        getAllLeiXing(dftId) { //获取所有设备类型
            this.$axios({
                method: 'get',
                url: "/common2/companyDeviceSecondTypes"
            }).then(res => {
                for (let i = 0; i < res.data.result.records.length; i++) {
                    this.leixingList[i] = {}
                    this.leixingList[i].value = res.data.result.records[i].dftId
                    this.leixingList[i].label = res.data.result.records[i].dftName
                    this.leixingList[i].children = [];
                }

                let leixingArr = []; //一级去重
                let leixingObj = {};
                for (let i = 0; i < this.leixingList.length; i++) {
                    if (!leixingObj[this.leixingList[i].label]) {
                        leixingArr.push(this.leixingList[i]);
                        leixingObj[this.leixingList[i].label] = true;
                    }
                }
                this.leixingList = leixingArr;

                for (let i = 0; i < this.leixingList.length; i++) { //获取二级数据
                    for (let j = 0; j < res.data.result.records.length; j++) {
                        if (this.leixingList[i].value === res.data.result.records[j].dftId) {
                            let leixing = {};
                            leixing.value = res.data.result.records[j].dstId;
                            leixing.label = res.data.result.records[j].dstName;
                            this.leixingList[i].children.push(leixing);
                        }
                    }
                }
                //二级菜单去重
                for (let i = 0; i < this.leixingList.length; i++) {
                    let departmentArr = [];
                    let departmentObj = {};
                    for (let j = 0; j < this.leixingList[i].children.length; j++) {
                        if (!departmentObj[this.leixingList[i].children[j].label]) {
                            departmentArr.push(this.leixingList[i].children[j]);
                            departmentObj[this.leixingList[i].children[j].label] = true;
                        }
                    }
                    this.leixingList[i].children = departmentArr;
                }
                // console.log("三级设备:",res);
            }).catch(err => {
                this.isError(err, this)
            })
        },
        remoteUnlockSize(size) {
            this.remoteUnlock.limit = size
            this.remoteUnlockRecord()
        },
        remoteUnlockCurrent(cursor) {
            this.remoteUnlock.cursor = cursor
            this.remoteUnlockRecord()
        },
        nameChange() { //设备名称查询
            this.form.cursor = 1
            this.getAllSheBei()
        },
        SNChange() { //设备SN查询
            this.form.cursor = 1
            this.getAllSheBei()
        },
        AddChange() { //设备地址查询
            this.form.cursor = 1
            this.getAllSheBei()
        },
        cachange(msg) { //设备类型查询
        console.log("设备类型查询:",this.form.params)
            this.form.params.devFirstType = msg[0]
            this.form.params.devSecondType = msg[1]
            this.form.cursor = 1
            this.getAllSheBei()
        },
        zhuangTaiShuaXin() { //状态刷新按钮
            // this.form.cursor=1
            this.getAllSheBei()
        },
        timeRefresh() { //定时刷新状态
            if (this.refreshState == '3s') {
                clearInterval(this.removeTime)
                this.removeTime = setInterval(() => {
                    this.getAllSheBei()
                }, 3000)
            } else if (this.refreshState == '5s') {
                clearInterval(this.removeTime)
                this.removeTime = setInterval(() => {
                    this.getAllSheBei()
                }, 5000)
            } else {
                clearInterval(this.removeTime)
            }
        },
        getMsgFormSon(data) { //子组件传过来的值
            this.selCity.bdclickrow = data
        },
        bangDingSheBei() { //绑定设备按钮
            if(this.qr.equipmentSelectArr.length < 1){this.$message.warning('请勾选最少一条记录');return}
            this.dialogQwe = true
            this.installationAddress = true
            // this.getHouse()
        },
        postBangDing() { //绑定更改提交
            let hsId = this.selCity.bdclickrow.hsId
            let arr = this.qr.equipmentSelectArr.map((key , index)=>{return key.jourDeviceId})
            this.loading4 = true
            this.$axios({
                url: `/common2/devices/bind`,
                method: 'POST',
                data: {
                    deviceIds:arr,//  设备ID集合
                    hsId:hsId// 房间Id
                }
            }).then(res => {
                this.loading4 = false
                this.dialogQwe = false
                this.$message.success('设备绑定成功')
                this.getAllSheBei()
                // if (res.data.result.changeBefore && res.data.result.changeAfter) {
                //     this.$notify({
                //         title: '设备地址变更',
                //         message: '变更前:' + res.data.result.changeBefore.hsAddCommunity + '，变更后：' + res.data.result.changeAfter.hsAddCommunity,
                //         duration: 5000
                //     });
                // }
            }).catch(err => {
                this.loading4 = false
                this.isError(err, this)
            })
        },
        bangDingSave() { //绑定设备保存按钮
            if (!this.selCity.bdclickrow) {
                this.$message.warning('请选择地址')
            } else {
                this.postBangDing()
            }
        },
        selAddChange(sel) { //获取选中位置id
            console.log(sel)
            this.form.selIdpId = sel
        },
        getAllPlace() { //获取所有情景位置
            this.$axios({
                method: 'get',
                url: '/common2/devicePlace'
            }).then(res => {
                this.qjPlace.place = res.data.result
                console.log("qjPlace:",res.data.result)
            }).catch(err => {
                this.isError(err, this)
            })
        },
        getAldevicePlace() {
            //获取所有情景位置
            this.$axios({
                method: 'get',
                url: '/common2/devicePlace',
            }).then((res) => {
                this.virtualTelecontrol.place = res.data.result
                console.log(this.virtualTelecontrol.place)
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        //获取所有网关
        getAllMac() {
            this.$axios({
                method: 'get',
                url: '/common2/devices/getAllMac',
            }).then((res) => {
                this.virtualTelecontrol.gateway = res.data.result;
                console.log(this.virtualTelecontrol.gateway)
            }).catch((err) => {
                this.isError(err, this);
            });
        },
        anZhuangClick() { //设备安装位置按钮
            if (!this.form.selrows && !this.form.clickrow) {
                this.$message.warning('请至少选择一个设备')
            } else {
                this.dialogSheBeiAnZhuangWeiZhi = true
                this.getAllPlace()
            }
        },
        putPlace() { //提交修改位置
            if (this.qjPlace.params.anzhuangweizhi) {
                if (this.form.clickrow && !this.form.selrows) {
                    var deviceIds = this.form.clickrow.jourDeviceId
                } else if (!this.form.clickrow && this.form.selrows) {
                    var deviceIds = this.form.selrows
                } else {
                    var deviceIds = this.form.selrows
                }
                this.loading3 = true
                let idpPlace = this.form.selIdpId
                this.$axios({
                    method: "put",
                    url: '/common2/devicePlace',
                    params: {
                        deviceIds,
                        idpPlace
                    }
                }).then(res => {
                    this.loading3 = false
                    this.getAllSheBei()
                    this.qjPlace.params.anzhuangweizhi = ""
                    this.dialogSheBeiAnZhuangWeiZhi = false
                    this.$message.success('修改成功')
                }).catch(err => {
                    this.loading3 = false
                    this.isError(err, this)
                })
            }
        },
        xiuGaiClick() { //修改设备类型
            if (!this.form.clickrow && !this.form.selrows) {
                this.$message.warning('请先选中一条记录')
            } else {
                let arr = []
                arr[0] = `${this.form.clickrow.devFirstType}`
                arr[1] = `${this.form.clickrow.devSecondType}`
                this.defaultType = arr
                this.row.fsid = this.form.clickrow.devFirstType
                this.row.lsid = this.form.clickrow.devSecondType
                this.dialogXiuGaiSheBeiLeiXing = true
            }
        },
        xiuGaiChange(msg) { //修改选择框
            this.row.fsid = msg[0]
            this.row.lsid = msg[1]
        },
        xiuGaiSave() { //修改保存按钮
            if (this.form.clickrow && !this.form.selrows) {
                var deviceIds = this.form.clickrow.jourDeviceId
            } else if (!this.form.clickrow && this.form.selrows) {
                var deviceIds = this.form.selrows
            } else {
                var deviceIds = this.form.selrows
            }
            let devIdftId = this.row.fsid
            let devIdstId = this.row.lsid
            this.loading2 = true
            this.$axios({
                method: 'put',
                url: "/common2/devices/campus/devices/batch",
                params: {
                    devIdftId,
                    devIdstId,
                    deviceIds
                }
            }).then(res => {
                this.dialogXiuGaiSheBeiLeiXing = false
                this.loading2 = false
                this.row.fsid = ''
                this.row.lsid = ''
                this.getAllSheBei()
                this.$message.success('修改成功')
            }).catch(err => {
                this.loading2 = false
                this.isError(err, this)
            })

        },
        getAllBrands() { //获取所有设备品牌
            this.$axios({
                method: 'get',
                url: '/common2/companyBrands'
            }).then(res => {
                this.addBrandsForm.params=this.addBrandsForm2
                console.log("获取所有设备品牌:",res.data.result)
                //获取一级菜单
                for (let i = 0; i < res.data.result.length; i++) {
                    this.brandsList[i] = {}
                    this.brandsList[i].value = res.data.result[i].brandName
                    this.brandsList[i].label = res.data.result[i].brandName
                    this.brandsList[i].children = [];
                }

                //一级去重
                let brandsListArr = [];
                let brandsListObj = {};
                for (let i = 0; i < this.brandsList.length; i++) {
                    if (!brandsListObj[this.brandsList[i].label]) {
                        brandsListArr.push(this.brandsList[i]);
                        brandsListObj[this.brandsList[i].label] = true;
                    }
                }
                this.brandsList = brandsListArr;
                //获取二级数据
                for (let i = 0; i < this.brandsList.length; i++) {
                    for (let j = 0; j < res.data.result.length; j++) {
                        if (this.brandsList[i].value === res.data.result[j].brandName) {
                            let brandsLi = {};
                            brandsLi.value = res.data.result[j].brandType;
                            brandsLi.label = res.data.result[j].brandType;
                            brandsLi.children = []
                            this.brandsList[i].children.push(brandsLi);
                        }
                    }
                }
                //二级菜单去重
                for (let i = 0; i < this.brandsList.length; i++) {
                    let brandsListmentArr = [];
                    let brandsListmentObj = {};
                    for (let j = 0; j < this.brandsList[i].children.length; j++) {
                        if (!brandsListmentObj[this.brandsList[i].children[j].label]) {
                            brandsListmentArr.push(this.brandsList[i].children[j]);
                            brandsListmentObj[this.brandsList[i].children[j].label] = true;
                        }
                    }
                    this.brandsList[i].children = brandsListmentArr;
                }
                //获取三级菜单
                for (let i = 0; i < this.brandsList.length; i++) {
                    for (let j = 0; j < this.brandsList[i].children.length; j++) {
                        for (let k = 0; k < res.data.result.length; k++) {
                            if (this.brandsList[i].label === res.data.result[k].brandName && this.brandsList[i].children[j].label === res.data.result[k].brandType) {
                                let department = {};
                                department.value = res.data.result[k].brandId;
                                department.label = res.data.result[k].brandModel;
                                this.brandsList[i].children[j].children.push(department);
                            }
                        }
                    }
                }
            }).catch(err => {
                this.isError(err, this)
            })
        },
        brandChange(id) { //品牌改变
            this.brandId = id[2];
            if (id[2] == 30 || id[2] == 31 || id[2] == 32 || id[2] == 33 || id[2] == 34 || id[2] == 23 || id[2]==35){
                this.$axios({
                    method: 'get',
                    url: "/common2/companyDeviceSecondTypes",
                    params: {
                        dftId: id[2] == 33 ? 38 : id[2] == 34 ? 39 : id[2] == 23 ?16:id[2]==35?42:33
                    }
                }).then(res => {
                    this.faceDevice.typeList = res.data.result.records
                }).catch(err => {
                    this.isError(err, this)
                })
            }
        },
        addNewEq() { //新增设备按钮
            this.getAllBrands()
            this.dialogTianJiaXinSheBei = true
        },
        saveNewEq() { //新增设备保存按钮
            if (!this.brandId) {
                this.$message.warning('请选择设备')
            } else if (this.submitForm(['addBrandsFormRules'], this)) {
                this.loading1 = true
                this.$axios({
                    method: 'post',
                    url: '/common2/facePersons/add/face',
                    params: {
                        deviceKey: this.addBrandsForm.params.deviceKey,
                        deviceName: this.addBrandsForm.params.deviceName
                    }
                }).then(res => {
                    this.dialogTianJiaXinSheBei = false
                    this.$message.success('添加成功')
                    this.loading1 = false
                    this.getAllSheBei()
                }).catch(err => {
                    this.loading1 = false
                    this.isError(err, this)
                })
            }
        },
        //添加设备点击确定 确定按钮
        getMusicHostDevice() {
            if (this.submitForm(['addBrandsFormRules'],this)) {
                let url=this.brandId==29?'/common2/devices/getMusicHostDevice':'/common2/devices/addFaceDevice'
                let method=this.brandId==29?'get':"post"
                let params=this.brandId==29?
                {account: this.addBrandsForm.params.deviceAccount,
                password: this.addBrandsForm.params.devicePassword}
                :{cid:"",
                        deviceName:this.addBrandsForm.params.deviceName,
                        hsId:"",	
                        brand:this.brandId,	
                        ip:this.addBrandsForm.params.ip,
                        firstType:this.typeID[0],
                        secondType:this.typeID[1],
                        userName:"",
                        password:"",}
                this.loading1 = true
                 // 添加设备
                 this.$axios({
                    method,
                    url,
                    params
                }).then(res=>{
                    this.loading1 = false
                    this.musicHost.dialogMusicHost = true
                    this.musicHost.dataList = res.data.result
                }).catch(err => {
                    this.loading1 = false
                    this.isError(err, this)
                })
            }

        },
        addMusicHost() {
            this.musicHost.loading = true
            this.$axios({
                method: 'post',
                url: '/common2/devices/addMusicHost',
                params: {
                    account: this.addBrandsForm.params.deviceAccount,
                    password: this.addBrandsForm.params.devicePassword
                },
                data: this.musicHost.dataList
            }).then(res => {
                this.musicHost.loading = false
                this.musicHost.dialogMusicHost = false
                this.$message.success("添加成功！");
                this.getAllSheBei()
            }).catch(err => {
                this.musicHost.loading = false
                this.isError(err, this)
            })
        },
        selectRooms() {
            if (this.submitForm(['addBrandsFormRules'], this)) {
                console.log('hh')
                this.installationAddress = true
                this.faceDevice.dialogAddFaceDevice = true
            }
        },
        closeDialogAddFaceDevice() {
            this.installationAddress = false
            this.faceDevice.typeList = []
        },
        addNewClose() { //新增设备弹窗关闭
            this.resetForm(['addBrandsFormRules'], this)
            this.addBrandsForm.params = {
                    secondType: '',
                    deviceKey: '',
                    deviceName: '',
                    deviceAccount: '',
                    devicePassword: '',
                    ip: ''
                },
                this.brandId = ''
        },
        selectUser(arr) {
            this.batchCard.userId = arr[2]
        },
        qingjingmoshi() {
            this.$router.push('/controlSetUp')
        },
        dingshirenwu() {
            this.$router.push('/timingControl')
        },
        increase() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.percentage += 33.333;
                if (this.percentage > 99.999) {
                    this.percentage = 99.999;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave() //一秒后才执行的请求
                    timer = undefined;
                }, 1000)
            }
        },
        decrease() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.percentage -= 33.333;
                if (this.percentage < 0) {
                    this.percentage = 0;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave() //一秒后才执行的请求
                    timer = undefined;
                }, 1000)
            }
        },
        unit(percentage) { //风速进度条
            if (percentage == 99.999) {
                this.collective.devAirWindSpeed = '高'
                return percentage == 99.999 ? '高' : `${percentage}%`;
            } else if (percentage == 66.666) {
                this.collective.devAirWindSpeed = '中'
                return percentage == 66.666 ? '中' : `${percentage}%`;
            } else if (percentage == 33.333) {
                this.collective.devAirWindSpeed = '低'
                return percentage == 33.333 ? '低' : `${percentage}%`;
            } else if (percentage == 0) {
                this.collective.devAirWindSpeed = '自动'
                return percentage == 0 ? '自动' : `${percentage}%`;
            }
        },
        increase2: function () { //事件防抖--温度加
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.percentage2 += 7.1;
                if (this.percentage2 > 99.4) {
                    this.percentage2 = 99.4;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave()
                    timer = undefined;
                }, 1000)
            }
        },
        decrease2() { //温度减
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.percentage2 -= 7.1;
                if (this.percentage2 < 0) {
                    this.percentage2 = 0;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave()
                    timer = undefined;
                }, 1000)
            }
        },
        unit2(percentage2) { //温度进度条
            let percentage2Tfix = percentage2.toFixed(1)
            if (percentage2Tfix == 99.4) {
                this.collective.devAirTemperature = 30
                return percentage2 = '30℃';
            } else if (percentage2Tfix == 92.3) {
                this.collective.devAirTemperature = 29
                return percentage2 = '29℃';
            } else if (percentage2Tfix == 85.2) {
                this.collective.devAirTemperature = 28
                return percentage2 = '28℃';
            } else if (percentage2Tfix == 78.1) {
                this.collective.devAirTemperature = 27
                return percentage2 = '27℃';
            } else if (percentage2Tfix == 71.0) {
                this.collective.devAirTemperature = 26
                return percentage2 = '26℃';
            } else if (percentage2Tfix == 63.9) {
                this.collective.devAirTemperature = 25
                return percentage2 = '25℃';
            } else if (percentage2Tfix == 56.8) {
                this.collective.devAirTemperature = 24
                return percentage2 = '24℃';
            } else if (percentage2Tfix == 49.7) {
                this.collective.devAirTemperature = 23
                return percentage2 = '23℃';
            } else if (percentage2Tfix == 42.6) {
                this.collective.devAirTemperature = 22
                return percentage2 = '22℃';
            } else if (percentage2Tfix == 35.5) {
                this.collective.devAirTemperature = 21
                return percentage2 = '21℃';
            } else if (percentage2Tfix == 28.4) {
                this.collective.devAirTemperature = 20
                return percentage2 = '20℃';
            } else if (percentage2Tfix == 21.3) {
                this.collective.devAirTemperature = 19
                return percentage2 = '19℃';
            } else if (percentage2Tfix == 14.2) {
                this.collective.devAirTemperature = 18
                return percentage2 = '18℃';
            } else if (percentage2Tfix == 7.1) {
                this.collective.devAirTemperature = 17
                return percentage2 = '17℃';
            } else if (percentage2Tfix == 0) {
                this.collective.devAirTemperature = 16
                return percentage2 = '16℃';
            }
        },
        increase3() { //亮度加
            if (this.lamplightState == '关' || this.lamplightState == '' || this.lamplightState == undefined) {
                this.$message.warning('请打开灯光开关')
            } else if (this.lamplightState == '开') {
                this.percentage3 += 10;
                if (this.percentage3 > 100) {
                    this.percentage3 = 100;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave() //一秒后才执行的请求
                    timer = undefined;
                }, 1000)
            }
        },
        decrease3() { //亮度减
            if (this.lamplightState == '关' || this.lamplightState == '' || this.lamplightState == undefined) {
                this.$message.warning('请打开灯光开关')
            } else if (this.lamplightState == '开') {
                this.percentage3 -= 10;
                if (this.percentage3 < 0) {
                    this.percentage3 = 0;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave()
                    timer = undefined;
                }, 1000)
            }
        },
        increase4() { //色温加
            if (this.lamplightState == '关' || this.lamplightState == '' || this.lamplightState == undefined) {
                this.$message.warning('请打开灯光开关')
            } else if (this.lamplightState == '开') {
                this.percentage4 += 10;
                if (this.percentage4 > 100) {
                    this.percentage4 = 100;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave()
                    timer = undefined;
                }, 1000)
            }
        },
        decrease4() { //色温减
            if (this.lamplightState == '关' || this.lamplightState == '' || this.lamplightState == undefined) {
                this.$message.warning('请打开灯光开关')
            } else if (this.lamplightState == '开') {
                this.percentage4 -= 10;
                if (this.percentage4 < 0) {
                    this.percentage4 = 0;
                }
                let that = this
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    that.collectiveSave()
                    timer = undefined;
                }, 1000)
            }
        },
        //集控全开全关
        socketPointOpen() { //插座开
            this.collective.devState = '开'
            this.collective.devType = '插座'
            this.collectiveSave()
            if (this.selectLocation.length > 0) {
                this.$refs.highlighted.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlighted2.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlighted3.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
            }
        },
        socketPointClose() { //插座关
            this.collective.devState = '关'
            this.collective.devType = '插座'
            this.collectiveSave()
            if (this.selectLocation.length > 0) {
                this.$refs.highlighted.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
                this.$refs.highlighted2.style.backgroundImage = 'linear-gradient(to right, #6ac7ea , #6ac7ea)'
                this.$refs.highlighted3.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
            }
        },
        conditionerOpen() {
            this.collective.devState = '开'
            this.collective.devType = '空调'
            this.collectiveSave()
            this.conditionerState = '开'
            if (this.selectLocation.length > 0) {
                //默认打开制冷
                this.$refs.pointRefrigeration.style.backgroundColor = '#53b552'
                this.percentage = 66.666
                this.percentage2 = 71.0
                //空调打开样式
                this.$refs.highlightedAir.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedAir2.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedAir3.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
            }
        },
        conditionerCloes() {
            this.collective.devState = '关'
            this.collective.devType = '空调'
            this.collectiveSave()
            this.conditionerState = '关'
            if (this.selectLocation.length > 0) {
                //关闭空调默认
                this.percentage = 0
                this.percentage2 = 0
                this.defaultState()
                //空调关闭样式
                this.$refs.highlightedAir.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
                this.$refs.highlightedAir2.style.backgroundImage = 'linear-gradient(to right, #6ac7ea , #6ac7ea)'
                this.$refs.highlightedAir3.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
            }
        },
        curtainOpen() {
            this.collective.devState = '开'
            this.collective.devType = '窗帘'
            this.collectiveSave()
            //窗帘样式
            if (this.selectLocation.length > 0) {
                this.$refs.highlightedCurtain.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedCurtain2.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedCurtain3.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
            }
        },
        curtainCloes() {
            this.collective.devState = '关'
            this.collective.devType = '窗帘'
            this.collectiveSave()
            //窗帘样式
            if (this.selectLocation.length > 0) {
                this.$refs.highlightedCurtain.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
                this.$refs.highlightedCurtain2.style.backgroundImage = 'linear-gradient(to right, #6ac7ea , #6ac7ea)'
                this.$refs.highlightedCurtain3.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
            }
        },
        lightOpen() {
            this.collective.devState = '开'
            this.collective.devType = '灯光'
            this.collectiveSave()
            this.lamplightState = '开'
            if (this.selectLocation.length > 0) {
                //灯光开启默认50
                this.percentage3 = 50
                this.percentage4 = 50
                //灯光样式
                this.$refs.highlightedLight.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedLight2.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
                this.$refs.highlightedLight3.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
            }
        },
        lightCloes() {
            this.collective.devState = '关'
            this.collective.devType = '灯光'
            this.collectiveSave()
            this.lamplightState = '关'
            if (this.selectLocation.length > 0) {
                //灯光关闭默认0
                this.percentage3 = 0
                this.percentage4 = 0
                //灯光样式
                this.$refs.highlightedLight.$el.style.backgroundImage = 'linear-gradient(to right, #02192e , #02192e)'
                this.$refs.highlightedLight2.style.backgroundImage = 'linear-gradient(to right, #6ac7ea , #6ac7ea)'
                this.$refs.highlightedLight3.$el.style.backgroundImage = 'linear-gradient(to right, #51b44d , #6ac7ea)'
            }
        },
        //集控空调状态
        defaultState() {
            this.$refs.pointRefrigeration.style.backgroundColor = '#9e9f9f'
            this.$refs.pointHeating.style.backgroundColor = '#9e9f9f'
            this.$refs.pointAutomatic.style.backgroundColor = '#9e9f9f'
            this.$refs.pointArefaction.style.backgroundColor = '#9e9f9f'
            this.$refs.pointAeration.style.backgroundColor = '#9e9f9f'
        },
        pointRefrigeration() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.defaultState()
                this.$refs.pointRefrigeration.style.backgroundColor = '#53b552'
                this.collective.devAirPattern = '制冷'
                this.collectiveSave()
            }
        },
        pointHeating() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.defaultState()
                this.$refs.pointHeating.style.backgroundColor = '#53b552'
                this.collective.devAirPattern = '制热'
                this.collectiveSave()
            }
        },
        pointAutomatic() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.defaultState()
                this.$refs.pointAutomatic.style.backgroundColor = '#53b552'
                this.collective.devAirPattern = '自动'
                this.collectiveSave()
            }
        },
        pointArefaction() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.defaultState()
                this.$refs.pointArefaction.style.backgroundColor = '#53b552'
                this.collective.devAirPattern = '除湿'
                this.collectiveSave()
            }
        },
        pointAeration() {
            if (this.conditionerState == '关' || this.conditionerState == '' || this.conditionerState == undefined) {
                this.$message.warning('请打开空调开关')
            } else if (this.conditionerState == '开') {
                this.defaultState()
                this.$refs.pointAeration.style.backgroundColor = '#53b552'
                this.collective.devAirPattern = '送风'
                this.collectiveSave()
            }
        },

        addLocation() { //添加集控位置
            this.locationTitle = '选择集控位置'
            this.locationDialog = true
            this.getQuery()
        },
        getQuery() {
            this.collectiveList = []
            this.$axios({
                method: 'get',
                url: '/common2/house4stores/containDevices',
                params: {
                    scenarios: true
                }
            }).then(res => {
                for (let i of res.data.result) {
                    let a = i.hsAddCommunity == null || i.hsAddCommunity == undefined ? '' : `${i.hsAddCommunity}\u3000`
                    let b = i.hsAddBuilding == null || i.hsAddBuilding == undefined ? '' : `${i.hsAddBuilding}\u3000`
                    let c = i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined ? '' : `${i.hsAddDoorplateno}`
                    i.hsAddCommunity = a + b + c
                    let obj = {
                        id: i.hsId,
                        value: i.hsAddCommunity
                    }
                    this.collectiveList.push(obj)
                }
            }).catch(err => {
                this.isError(err, this)
            })
        },
        remoteUnlockRecord() {
            this.changestatus.jourDeviceId = this.form.clickrow.jourDeviceId
            this.$axios({
                method: 'get',
                url: `/common2/unlockRecord/${this.changestatus.jourDeviceId}`,
                params: {
                    cursor: this.remoteUnlock.cursor,
                    limit: this.remoteUnlock.limit
                }
            }).then(res => {
                this.remoteUnlock.dataList = res.data.result.records
                this.remoteUnlock.total = res.data.result.total
                console.log(res.data.result)
            }).catch(err => {
                this.isError(err, this)
            })
        },
        intersect(arr1, arr2) { //取两数组的交集
            this.selectLocationInput = ''
            arr1.filter((item, index) => {
                if (arr2.indexOf(item.id) !== -1) {
                    this.selectLocationInput += arr1[index].value + ' '
                }
            })
        },
        locationDialogSave() { //位置设备保存
            this.intersect(this.collectiveList, this.selectLocation);
            this.locationDialog = false
        },
        async collectiveSave() {
            if (this.selectLocation.length <= 0) {
                this.$message.warning('请选择需要控制的位置')
            } else {
                this.deviceInfoData = await this.collectiveSaveRequest() //获取设备列表
                if (this.deviceInfoData.length == 0) {
                    this.$message.warning("没有设备")
                    return
                }
                // await this.collectivecsRoad()//获取路数
                this.collectiveRequest() //批量控制设备
            }
        },
        collectiveSaveRequest() { //获取设备列表
            return this.$axios({
                method: 'get',
                url: '/common2/devices/list/operate',
                params: {
                    hsIds: this.selectLocation.join(','),
                    devType: this.collective.devType,
                }
            }).then(res => {
                for (let i of res.data.result) {
                    let a = i.hsAddCommunity == null || i.hsAddCommunity == undefined ? '' : `${i.hsAddCommunity}\u3000`
                    let b = i.hsAddBuilding == null || i.hsAddBuilding == undefined ? '' : `${i.hsAddBuilding}\u3000`
                    let c = i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined ? '' : `${i.hsAddDoorplateno}`
                    i.hsAddCommunity = a + b + c
                }
                return res.data.result
            }).catch(err => {
                this.isError(err, this)
            })
        },
        collectivecsRoad() { //获取设备路数
            for (let i of this.deviceInfoData) {
                if (i.devRoad == 0) {
                    this.$axios({
                        method: 'get',
                        url: '/common2/eventsCodeStorages',
                        params: {
                            sn: i.devSn
                        }
                    }).then(res => {
                        console.log(res);
                        i.devRoad = res.data.result.csRoad
                    }).catch(err => {
                        if (err.response.status == 404) {
                            console.log('设备路数不存在')
                        } else {
                            this.isError(err, this)
                        }
                    })
                } else {
                    i.devRoad = i.devRoad - 1
                }
            }
        },
        async collectiveRequest() { //批量控制设备请求
            let ids = []
            for (let i of this.deviceInfoData) {
                if (i.result == undefined) {
                    this.$set(i, "result", "")
                }
                ids = await this.batchControl(i, ids);
            }
            if (ids.length == 0) return
            //获取设备状态改webScoket
            // this.$axios({
            //     method: 'get',
            //     url: `/common2/devices/getBatchState`,
            //     params: {
            //         ids: ids.join(',')
            //     }
            // }).then(res => {
            //     this.collective.controlState = res.data.result
            //     for (let i of this.deviceInfoData) {
            //         for (let j of this.collective.controlState) {
            //             if (i.jourDeviceId == j.id) {
            //                 this.$set(i, "State", "")
            //                 i.State = j.stateInformation
            //             }
            //         }
            //     }

            // }).catch(err => {
            //     this.isError(err, this)
            // })
            this.deviceInfo = true
        },
        batchControl(data, ids) {
            return this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    devId: data.jourDeviceId,
                    devNumber: data.devRoad, //路数
                    devState: this.collective.devState, //开关
                    devBrightness: this.percentage3, //亮度
                    devColorTemperature: this.percentage4, //色温
                    devAirTemperature: this.collective.devAirTemperature, //空调温度
                    devAirWindSpeed: this.collective.devAirWindSpeed, //空调风速
                    devAirPattern: this.collective.devAirPattern, //空调模式
                }
            }).then(res => {
                data.result = '成功'
                ids.push(data.jourDeviceId)
                return ids
            }).catch(err => {
                data.result = '失败'
                // this.keyData++
                this.isError(err, this)
                return ids
            })
        },
        retry(row) { //重试请求
            console.log(row);
            this.$axios({
                method: 'post',
                url: '/common2/devices/operate',
                data: {
                    devId: row.jourDeviceId,
                    devNumber: row.devRoad, //路数
                    devState: this.collective.devState, //开关
                    devBrightness: this.percentage3, //亮度
                    devColorTemperature: this.percentage4, //色温
                    devAirTemperature: this.collective.devAirTemperature, //空调温度
                    devAirWindSpeed: this.collective.devAirWindSpeed, //空调风速
                    devAirPattern: this.collective.devAirPattern, //空调模式
                }
            }).then(res => {
                this.$set(row, "State", "")
                row.result = '成功'
                //获取设备状态
                this.$axios({
                    method: 'get',
                    // url: `/common2/devices/getBatchState`,
                    url: `/common2/devices/getDevicesState`,
                    params: {
                        ids: row.jourDeviceId
                    }
                }).then(res => {
                    row.State = res.data.result[0].stateInformation
                }).catch(err => {
                    this.isError(err, this)
                })
            }).catch(err => {
                row.result = '失败'
                // this.keyData++
                this.isError(err, this)
            })
        },
        DialogPermissionsClose() {
            this.equipmentPermissions.defaultSelect = ""
            this.equipmentPermissions.defaultSelect2 = ""
            this.equipmentPermissions.selectionIndexPath = []
            this.equipmentPermissions.selectionIndexPath2 = []
            this.equipmentPermissions.asideConfigHave = []
        },
        getNotConfiguredDevice() { //未添加的设备
            this.$axios({
                url: "/common4/hotelAligenieDevice/notConfiguredDevice",
                method: "get",
                params: {
                    position: this.equipmentPermissions.selectionIndexPath2[0],
                    deviceType: this.equipmentPermissions.selectionIndexPath2[1]
                }
            }).then(res => {
                this.equipmentPermissions.asideConfigNoHave = res.data.result
            }).catch(err => {
                this.isError(err, this)
            })
        },
        getElvesData() {//获取天猫精灵位置和类型
            this.$axios({
                url: "/common4/companyHotelAligenieConfig",
                method: "get",
            })
                .then((res) => {
                    let result = res.data.result,
                        arr = [];
                    for (const i of result.position) {
                        arr.push({ location: i, type: result.deviceType });
                    }
                    this.equipmentPermissions.asideList = arr;
                    this.equipmentPermissions.defaultSelect =
                        arr[0].location + "-" + arr[0].type[0];
                    this.equipmentPermissions.defaultSelect2 =
                        arr[0].location + "-" + arr[0].type[0];
                    this.equipmentPermissions.selectionIndexPath = this.equipmentPermissions.defaultSelect.split(
                        "-"
                    );
                    this.equipmentPermissions.selectionIndexPath2 = this.equipmentPermissions.selectionIndexPath;
                    this.getConfiguredDevice();
                })
                .catch((err) => {
                    this.isError(err, this);
                });
        },
        getConfiguredDevice() { //已添加的设备
            this.$axios({
                url: "/common4/hotelAligenieDevice/configuredDevice",
                method: "get",
                params: {
                    position: this.equipmentPermissions.selectionIndexPath[0],
                    deviceType: this.equipmentPermissions.selectionIndexPath[1]
                }
            }).then(res => {
                this.equipmentPermissions.asideConfigHave = res.data.result
            }).catch(err => {
                this.isError(err, this)
            })
        },
        openDialogBatchCard(type) {
            this.batchCard.type = type
            if (type) {
                this.batchCard.title = '注销管理卡'
                this.$axios({
                    method: "get",
                    url: `/common2/doorCard/getManagementCard`,
                }).then(res => {
                    this.batchCard.managementCards = res.data.result
                    this.batchCard.dialogBatchCard = true
                }).catch(err => {
                    this.isError(err, this)
                })
            } else {
                this.batchCard.title = '添加管理卡'
                this.batchCard.dialogBatchCard = true
            }
        },
        dialogBatchCardClose() {
            this.batchCard = {
                dialogBatchCard: false,
                title: "添加管理卡",
                loading: false,
                cardId: '',
                user: '',
                userId: '',
                rules: {
                    cardId: [{
                        required: true,
                        message: '授权卡号不能为空',
                        trigger: 'blur',
                    }, ],
                    userId: [{
                        required: true,
                        message: '用户不能为空',
                        trigger: 'blur',
                    }]
                }
            }
            this.resetForm(['checkBatchCard'], this)
        },
        doBatchCard() {
            if (this.submitForm(['checkBatchCard'], this)) {
                if (this.batchCard.title == '添加管理卡') {
                    this.$axios({
                        method: "post",
                        url: `/common2/doorCard/batchCard`,
                        params: {
                            jdcCardId: this.batchCard.cardId,
                            userId: this.batchCard.userId
                        }
                    }).then(res => {

                    }).catch(err => {
                        this.isError(err, this)
                    })
                    this.$message.success('批量授权已执行，预计5分钟后生效')
                    this.batchCard.dialogBatchCard = false
                } else {
                    this.$axios({
                        method: "put",
                        url: `/common2/doorCard/cancelBatchCard/${this.batchCard.cardId}`,
                    }).then(res => {

                    }).catch(err => {
                        this.isError(err, this)
                    })
                    this.$message.success('注销管理卡已执行，预计5分钟后生效')
                    this.batchCard.dialogBatchCard = false
                }
            }
        },
        async qrcode() { //打印二维码
            let co = sessionStorage.getItem('co')
            this.qr.qrArr = []
            let temporaryArr = []
            if (this.qr.equipmentSelectArr.length == 0) {
                await this.checkAllFun()
                temporaryArr = this.qr.checkAll
                temporaryArr.forEach(i => {
                    let a = i.hsAddCommunity == null || i.hsAddCommunity == undefined ? '' : `${i.hsAddCommunity}\u3000`
                    let b = i.hsAddBuilding == null || i.hsAddBuilding == undefined ? '' : `${i.hsAddBuilding}\u3000`
                    let c = i.hsAddDoorplateno == null || i.hsAddDoorplateno == undefined ? '' : `${i.hsAddDoorplateno}`
                    i.hsAddCommunity = a + b + c
                })
            } else {
                temporaryArr = this.qr.equipmentSelectArr
            }
            temporaryArr.forEach((item) => {
                let obj = {
                    hsAddCommunity: item.hsAddCommunity,
                    url: `http://fzz1.cn/b?c=${co}&a=${item.devId}`,
                    devAuthId: item.devAuthId,
                    devNickname: item.devNickname,
                    qrText: `${item.devSn}${item.devPasswd ? '-' + item.devPasswd : ''}`,
                };
                this.qr.qrArr.push(obj);
            });
            this.qr.dialogQr = true
        },
        openEquipmentSplit() {
            console.log(this.form.clickrow)
            if (!this.form.clickrow) {
                this.$message.warning('请选择一个设备进行操作')
                return
            }
            this.$axios({
                method: 'get',
                url: `/common2/devices/queryDeviceRoads/${this.form.clickrow.devSn}`,
            }).then(res => {
                this.equipmentSplit.dataList = res.data.result
                if (res.data.result[0].totalRoads > 1) {
                    this.equipmentSplit.dialogEquipmentSplit = true
                } else {
                    this.$message.warning('该设备无法进行拆分')
                }
                for (let i = 0; i < res.data.result[0].totalRoads; i++) {
                    let flag = this.equipmentSplit.dataList.some((item, index, array) => {
                        return item.devRoad == i
                    })
                    if (flag) {
                        continue
                    }
                    let obj = {
                        devRoad: i,
                    }
                    this.equipmentSplit.paramList.push(obj)
                }
            }).catch(err => {
                this.isError(err, this)
            })
        },
        closeEquipmentSplit() {
            this.equipmentSplit = {
                dataList: [],
                paramList: [],
                dialogEquipmentSplit: false
            }
            this.resetForm(['checkDevNickname'], this)
        },
        doEquipmentSplit() {
            if (this.submitForm(['checkDevNickname'], this)) {
                this.$axios({
                    method: 'post',
                    url: `/common2/devices/doEquipmentSplit/${this.form.clickrow.jourDeviceId}`,
                    data: this.equipmentSplit.paramList
                }).then(res => {
                    this.$message.success("拆分设备成功")
                    this.equipmentSplit.dialogEquipmentSplit = false
                }).catch(err => {
                    this.isError(err, this)
                })
            }
        },
        addFaceDevice() {
            if (!this.selCity.bdclickrow) {
                this.$message.warning("请选择一个房间绑定");
                return;
            }
            this.faceDevice.loading = true
            if(this.brandId == 23) {
                return this.$axios({
                    method: "post",
                    // url: `/common2/addMandunDevice/${this.selCity.bdclickrow.hsId}/${this.addBrandsForm.params.ifSplit}`,
                    // ${this.addBrandsForm.params.ifSplit}
                    url: `/common2/devices/addMandunDevice/${this.selCity.bdclickrow.hsId}/1`,
                    data: {
                        devBrandId: this.brandId,
                        devFirstType: '16',
                        devSecondType: this.addBrandsForm.params.secondType,
                        devSn: this.addBrandsForm.params.deviceKey,
                        devNickname: this.addBrandsForm.params.deviceName,
                        password: this.addBrandsForm.params.devicePassword,
                        userName: this.addBrandsForm.params.deviceUserName
                    }
                })
                .then(res => {
                    this.$message.success("添加设备成功");
                    this.faceDevice.loading = false;
                    this.faceDevice.dialogAddFaceDevice = false;
                    this.dialogTianJiaXinSheBei = false;
                    this.getAllSheBei()
                })
                .catch(err => {
                    this.faceDevice.loading = false;
                    this.isError(err, this);
                });
            }
            this.$axios({
                method: 'post',
                url: `/common2/devices/addFaceDevice`,
                params: {
                    brand: this.brandId,
                    //this.brandId == 33 ? 38 : this.brandId == 34 ? 39 : 33
                    firstType: this.brandId == 33 ? 38 : this.brandId == 34 ? 39 : this.brandId == 23 ?16:this.brandId==35?42:33,
                    secondType: this.faceDevice.typeList[0].dstId,
                    hsId: this.selCity.bdclickrow.hsId,
                    cid: this.addBrandsForm.params.deviceKey,
                    deviceName: this.addBrandsForm.params.deviceName,
                    ip: this.addBrandsForm.params.ip+`:${this.addBrandsForm.params.portNumber}`,
                    password: this.addBrandsForm.params.devicePassword,
                    userName: this.addBrandsForm.params.deviceUserName,
                }
            }).then(res => {
                this.$message.success("添加设备成功")
                this.faceDevice.loading = false
                this.faceDevice.dialogAddFaceDevice = false
                this.dialogTianJiaXinSheBei = false
            }).catch(err => {
                this.faceDevice.loading = false
                this.isError(err, this)
            })
        },
        addVirtualTelecontrol(){
            this.virtualTelecontrol.dialogvirtualTelecontrol=true
            this.getAldevicePlace()
            this.getAllMac()
        },
        selectRoom(title){
            this.taskAffiliationDialog = true
            this.taskAffiliationDialogTitle = title
        },
        selectRoomDblclick(row) {
            this.taskAffiliationDialog = false;
            this.virtualTelecontrol.address = row.address
            this.virtualTelecontrol.hsId = row.hsId
            console.log(row);
        },
        virtualTelecontrolSubmit(){
            if(!this.submitForm(['virtualTelecontrol'], this)) return
            let co = sessionStorage.getItem("co");
            let userCoding = sessionStorage.getItem("userCoding");
            this.$axios({
                method: 'POST',
                url: `/common2/devices/addVirtual`,
                data: {
                    sn: this.virtualTelecontrol.sn ? 'C15359' + this.virtualTelecontrol.sn : "C1", //写死
                    chipModule: 2, //写死
                    type:193,//写死
                    mac: this.virtualTelecontrol.mac,
                    name: this.virtualTelecontrol.name,
                    hsId: this.virtualTelecontrol.hsId,
                    subtype: this.virtualTelecontrol.subtype[1],
                    place: `${co+"/"+this.virtualTelecontrol.hsId}`,
                    co: co,
                    userId: userCoding,
                },
            }).then((res) => {
                console.log(res)
                if(res.data.status == 200){
                    this.$message.success('添加成功');
                    this.getAllSheBei()
                    this.virtualTelecontrol.dialogvirtualTelecontrol = false;
                }
            }).catch((err) => {
                this.csRoadDevices = {};
                this.isError(err, this);
            });
        },
        verifySNCode(rule, value, callback) {
            if (!value) {
                return callback()
            }
            if(!/^[a-zA-Z0-9]{8,8}$/.test(value)){
                return callback(new Error('请输入8位数字或字母'))
            }
        },
        visualization() {
            this.$router.push('/visualization');
        },
        roomControl() {
            this.$router.push('/roomControl');
        }
    },
}
</script>

<style lang="scss">
.totop{
  height:100%;
  cursor: pointer;
}
.collection{
  margin-left: 20px;
  cursor: pointer;
}
.visualization-setting__i{
    cursor: pointer;
    color:#fff;
    padding-bottom: 10px;
    .fz-24{
        font-size: 20px;
    }
}
.Part {
    .el-button--mini {
        margin: 5px !important;
        font-size: 16px;
    }
}
</style>
<style lang="scss" scoped>

.shuaxin {
    font-size: 12px !important;
    float: right;
}

.timeshuax {
    width: 150px;
    float: right;
    line-height: 35px
}

/*设备集控样式*/
.equipmentWith /deep/ .el-dialog__header,
.equipmentWith /deep/ .el-dialog__body {
    background-color: #02192e;
}

.topLeft {
    background-color: #1d364f;
    width: 80%;
    margin: 20px auto;
    border-radius: 15px;
    text-align: center;

    p {
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        margin-bottom: 15px;
        padding-top: 20px;
    }

    .switch {
        height: 8px;
        width: 30%;
        background-color: #717071;
        border-radius: 15px;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    .controlButton {
        width: 80%;
        margin-bottom: 15px;
        margin-left: 0;
        font-size: 20px;
        color: #ffffff;
        background-color: #02192e;
        border-color: #02192e;
        font-weight: 700;
    }
}

/*  进度条样式*/
.progressBar {
    width: 200px;
    margin: 20px auto 0;
}

/deep/ .el-progress-bar__outer {
    background-color: #304156;
}

.progressButton {
    margin: 19px 0;

    span {
        color: #ffffff;
        font-size: 20px;
        padding: 0 10px;
    }

    .el-button {
        height: 53px;
        width: 55px;
        background-color: #1d364f;
        border-color: #1d364f;
    }

    /deep/ .el-icon-plus,
    /deep/ .el-icon-minus {
        font-size: 20px;
    }

    /deep/ .el-icon-plus:before,
    /deep/ .el-icon-minus:before {
        color: #ffffff;
    }
}

/deep/ .el-progress-bar__inner {
    background-image: linear-gradient(to right, #51b44d, #6ac7ea);
}

/deep/ .el-progress-bar__innerText {
    font-size: 18px;
}

/*  空调模式样式*/
.equipmentWith {
    .model {
        width: 90%;
        margin: 0 auto;

        .modelChild {
            width: 20%;
            float: left;
            text-align: center;

            .dot {
                height: 10px;
                width: 10px;
                background-color: #9e9f9f;
                border-radius: 50%;
                margin: 5px auto 0;
            }

            p {
                color: #ffffff;
                font-size: 16px;
                margin: 5px 0;
            }

            .bigDot {
                height: 52px;
                width: 50px;
                background-color: #9e9f9f;
                border-radius: 50%;
                margin: 10px auto;
                cursor: pointer;
            }
        }
    }
}

/*选择位置样式*/
.location {
    height: 100px;
    width: 440px;
    margin: 20px auto 0;
    background-color: #304156;

    /deep/ .el-textarea {
        margin: 12px;
    }

    .locationButton {
        /deep/ .el-button {
            margin-top: 9px;
            margin-left: 16px;
            border: none;
            font-size: 16px;
            color: #ffffff;
            font-weight: 700;
            background-image: linear-gradient(to right, #51b44d, #6ac7ea);
        }
    }

    /deep/ .el-divider {
        color: #000000;
        height: 2px;
        margin: 10px 0;
    }
}


.qr-code {
    height: 220px;

    p {
        height: 20px;
        margin: 5px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
}
/deep/.el-checkbox:last-of-type {
    margin-right: 30px;
}
</style>
