<template>
  <el-table :data="data" height="400px" ref="table" v-loading="load">
    <el-table-column type="selection" width="55"></el-table-column>
    <el-table-column prop="devNickname" label="设备名称"></el-table-column>
    <el-table-column label="设备地址">
      <template slot-scope="scope">
        <span
          >{{ scope.row.hsAddCommunity }} {{ scope.row.hsAddDoorplateno }}</span
        >
      </template>
    </el-table-column>
    <el-table-column label="删除" width="100px" v-if="!mold">
      <template slot-scope="scope">
        <el-button
          type="danger"
          plain
          size="mini"
          style="font-size: 12px!important; margin: 0!important"
          @click="DelEquipment(scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
    <el-table-column label="添加" width="100px" v-else>
      <template slot-scope="scope">
        <el-button
          type="success"
          plain
          size="mini"
          @click="AddEquipment(scope.row)"
          style="font-size: 12px!important; margin: 0!important"
          >添加</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    mold: {
      type: Number,
    },
    load: {
      type:Boolean,
      default: false
    }
  },
  methods: {
    allcheked() {
      return this.$refs.table.selection;
    },
    DelEquipment(data) {
      console.log(data.hadId);
      // let all =
      // console.log(all)
      let id = [].concat(data.hadId);
      console.log(id);
      this.$axios({
        url: "/common4/hotelAligenieDevice/deleteConfiguredDevice",
        method: "post",
        data: [data.hadId],
      }).then((res) => {
        this.$emit("del");
      });
    },
    AddEquipment(row) {
      this.$emit('addDevice',row)
    },
    // relist() {
    //   this.$refs.device1.reagin()
    // },
    // readdlist() {
    //   this.$refs.device2.reagin()
    // }
  },
};
</script>

<style></style>
