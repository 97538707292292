<template>
  <el-tabs
    type="border-card"
    @tab-click="tabcharge"
    v-model="title"
  >
    <el-tab-pane :label="item" v-for="(item, index) in position" :key="index">
      <!-- <p v-if="index == title">{{ type }}</p> -->
      <v-device
        :type="item"
        v-if="index == title"
        :mold="mold"
        ref="device"
        @addevice="addevice"
        @relist="relist"
        :load="loading"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import VDevice from "./device";
export default {
  name: "DevicePower",
  data() {
    return {
      position: [],
      type: '',
      title: this.idx,
      loading:false
    };
  },
  components: {
    VDevice,
  },
  props: {
    mold: {
      type: Number,
      default: 0,
    },
    check: {
      type:String,
      default:''
    },
    idx: {
      type:String
    }
  },
  watch: {
    check:{
      immediate:true,
      handler:function(val) {
        console.log(val)
        this.type = val
      }
    }
  },
  created() {
    this.findDevice();
  },
  activated() {
    console.log(active)
  },
  methods: {
    findDevice() {
      this.$axios({
        url: "/common4/companyHotelAligenieConfig",
        method: "get",
      }).then((res) => {
        let data = res.data.result;
        this.position = data.position;
      });
    },
    tabcharge(e) {
      this.type = e.label;
    },
    addevice() {
      // 触发外部事件
      this.$emit('addevice',{check: this.type,idx:this.title})
    },
    relist() {
      this.$emit('relist')
    },
    reagin() {
      let kk = this.$refs.device[0]
      this.$refs.device[0].list(kk.type,kk.value)
    }
  },
};
</script>

<style></style>
